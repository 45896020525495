import React, { useRef } from 'react';
import CreatableSelect from 'react-select/creatable';

import './../SearchableDropdown/SearchableDropdown.css';

const MultiSelect = ({
  id,
  value,
  valueKey,
  labelKey,
  options,
  removeSelected = false,
  placeholder = 'Select...',
  handleChange,
  disabled = false,
  searchable = true,
  clearable = true,
  className = '',
  // additionalSearchFilter = '',
  noResultsText = 'No results found',
  // validationCheck = false,
  onBlur,
  size = 'medium',
}) => {
  const selectRef = useRef(null);

  const handleSelectChange = (selectedOptions) => {
    handleChange(selectedOptions);

    setTimeout(() => {
      if (selectRef.current) {
        selectRef.current.focus();
      }
    }, 0);
  };

  return (
    <CreatableSelect
      ref={selectRef}
      className={`searchable-dropdown ${size} ${className}`}
      classNamePrefix="searchable-dropdown"
      id={id}
      value={options.filter((option) =>
        value.some((v) => v === option[valueKey])
      )}
      getOptionLabel={(option) => option[labelKey]}
      getOptionValue={(option) => option[valueKey]}
      options={options}
      isMulti={true}
      onChange={handleSelectChange}
      placeholder={placeholder}
      isDisabled={disabled}
      isSearchable={searchable}
      isClearable={clearable}
      hideSelectedOptions={removeSelected}
      onBlur={onBlur}
      noOptionsMessage={() => noResultsText}
      formatOptionLabel={(option) =>
        option.custom ? (
          <div>
            <div>{option[labelKey]}</div>
            <div style={{ fontSize: '10px', color: 'grey' }}>{option.sku}</div>
          </div>
        ) : (
          option[labelKey]
        )
      }
      filterOption={(option, inputValue) => {
        const label = option.data[labelKey] || '';
        const custom = option.data.custom || '';
        return (
          label.toLowerCase().includes(inputValue.toLowerCase()) ||
          custom.toLowerCase().includes(inputValue.toLowerCase())
        );
      }}
    />
  );
};

export default MultiSelect;
