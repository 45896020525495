import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { MdHelpOutline } from '../../../Common/Icons';
import { restRequest } from '../../../Helpers/RequestHelper';
import { EMAIL_OPTIONS } from '../../../Constants';
import OverlayLoader from '../../../Components/Loader/OverlayLoader';
import Form from '../../../Components/Form/Form';
import Field from '../../../Components/Form/Field/Field';
import { Checked } from '../../../Assets/List/ListIcons';
import FormAction from '../../../Components/Form/FormAction';
import {
  getObjFromLS,
  // setObjInLS
} from '../../../Helpers/LocalStorage';

import './InvoicePreference.css';
import { checkError } from '../../../Helpers/AuthHelper';
import { isEqual } from 'lodash';

class InvoicePreference extends Component {
  state = {
    loading: true,
    staticVal: {},
    showPrompt: false,
    invoicePref: {
      displayStatus: false,
    },
    terms: '',
    show_admin_records: true,
    edit_locked_invoice: true,
    can_edit_by: 'owner',
    isSubmitting: false,
    email_default: {
      id: 'once',
      name: 'Once',
    },
  };

  componentDidMount() {
    localStorage.setItem('alert', false);
    this.fetchData();
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { terms, staticVal } = this.state;

    if (prevState.showPrompt !== this.state.showPrompt) {
      const checkEquality = Boolean(isEqual(terms, staticVal));
      if (!checkEquality) localStorage.setItem('alert', true);
      else localStorage.setItem('alert', false);
    }
  }
  card_options = [
    {
      label: 'Owner',
      value: 'owner',
    },
    {
      label: 'Every One',
      value: 'everyone',
    },
  ];
  handlePrompt = () => {
    this.setState({
      showPrompt: !this.state.showPrompt,
    });
  };

  async fetchData() {
    this.setState({ loading: true });
    await restRequest('get', 'preferences/invoice')
      .then((res) => {
        this.setState({
          //invoicePref: {displayStatus: res.preferences.display_invoice_status,},
          email_default: {
            id: res.preferences.email_notification
              ? res.preferences.email_notification
              : 'once',
          },
          terms: res.terms,
          staticVal: res.terms,
          show_admin_records: res.preferences.show_admin_records,
          edit_locked_invoice: res.preferences.edit_locked_invoice,
          can_edit_by: res.preferences.can_edit_by,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    //let { displayStatus } = this.state.invoicePref
    let payload = {
      status: {
        email_notification: this.state.email_default.id,
        show_admin_records: this.state.show_admin_records,
        edit_locked_invoice: this.state.edit_locked_invoice,
        can_edit_by: this.state.can_edit_by,
      },
      terms: this.state.terms,
    };
    //console.log('payload', payload)
    this.setState({ isSubmitting: true });
    restRequest('put', 'preferences/invoice', payload)
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.handleToast(
          'Invoice preference updated successfully',
          'success'
        );
        this.props.setPrompt(false);
        this.fetchData();
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });
        checkError(error);
        this.props.handleToast(error, 'error');
      });
  };

  toggleInvoiceDisplayStatus() {
    this.setState((state) => ({
      invoicePref: {
        ...state.invoicePref,
        displayStatus: !state.invoicePref.displayStatus,
      },
    }));
  }

  handleEmailPrefences(value) {
    this.setState((state) => ({
      email_default: {
        id: value.id,
        name: value.name,
      },
    }));
  }

  salesAdminRecord() {
    return (
      <div className="float-left w-100 invoice-terms-pref __invoice-main--block">
        <label>Sales Person</label>
        <label className="list-table--checkbox-lable pointer">
          <input
            className="float-left"
            checked={this.state.show_admin_records}
            type="checkbox"
            onChange={() =>
              this.setState({
                show_admin_records: !this.state.show_admin_records,
              })
            }
            id="country"
          />
          <span className="float-left">
            <Checked />
          </span>
          <div style={{ marginLeft: '25px' }} className="admin-block">
            Show Admin Related Entries To Sales Manager{' '}
            <span>
              (this is directly linked to the User Permissions & Roles. Be
              careful when selecting it)
            </span>
          </div>
        </label>
        <label className="list-table--checkbox-lable pointer">
          <input
            className="float-left"
            checked={this.state.edit_locked_invoice}
            type="checkbox"
            onChange={() =>
              this.setState({
                edit_locked_invoice: !this.state.edit_locked_invoice,
              })
            }
            id="country"
          />
          <span className="float-left">
            <Checked />
          </span>

          <div style={{ marginLeft: '25px' }} className="admin-block">
            Edit/Delete the invoice while it is in a locked status
          </div>
        </label>
        {this.state.edit_locked_invoice && (
          <label className="list-table--checkbox-lable float-left w-100 preferences-deduction">
            <div className="sale-return-preference--label-text float-left w-auto">
              Choose who is authorized to edit an invoice in locked status
            </div>
            <Field
              className="float-left"
              type="dropdown"
              size="medium"
              value={this.state.can_edit_by}
              options={this.card_options}
              dropdownValue="value"
              dropdownLabel="label"
              searchable={false}
              clearable={false}
              handleChange={(value) => {
                this.setState({ can_edit_by: value.value });
              }}
            />
          </label>
        )}
      </div>
    );
  }
  renderActionButtons() {
    // return (
    //   <FormAction disabled={this.state.isSubmitting} onCancel={() => this.props.history.push('/settings')} />
    // )
    let permissions = getObjFromLS('role').permissions;
    if (!permissions.superAccess) {
      if (permissions.PreferenceEdit) {
        return (
          <FormAction
            disabled={this.state.isSubmitting || !this.state.terms}
            onCancel={() => this.props.history.push('/settings')}
          />
        );
      }
    }
    if (permissions.superAccess) {
      return (
        <FormAction
          disabled={this.state.isSubmitting}
          onCancel={() => this.props.history.push('/settings')}
        />
      );
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
    this.handlePrompt();
  };

  invoiceTermsContent() {
    return (
      <div className="float-left w-100 invoice-terms-pref">
        <Field
          name="Terms & Conditions"
          id="terms"
          type="textarea"
          value={this.state.terms}
          placeholder="Terms & Conditions"
          handleChange={this.handleChange}
        />
      </div>
    );
  }

  emailNotificationContent() {
    return (
      <div className="email-preference">
        <label className="form-label mb-5">
          {/* <div className="float-left w-auto"> */}
          Email Notification
          {/* </div> */}
        </label>
        <Field
          type="dropdown"
          id="emailnotifyID"
          // size="large"
          value={this.state.email_default.id}
          options={EMAIL_OPTIONS}
          dropdownValue="id"
          dropdownLabel="name"
          searchable={false}
          clearable={false}
          handleChange={(option) => {
            this.handleEmailPrefences(option);
          }}
        />
      </div>
    );
  }

  invoiceStampContent() {
    return (
      <label className="list-table--checkbox-lable pointer">
        <input
          className="float-left"
          checked={this.state.invoicePref.displayStatus}
          type="checkbox"
          onChange={() => this.toggleInvoiceDisplayStatus()}
        />
        <span className="float-left">
          <Checked />
        </span>
        <div className="invoice-preference--label-text float-left">
          Display invoice status stamp?
        </div>
      </label>
    );
  }

  renderStatusChangeInput() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <div className="invoice-preference float-left w-100">
          <div className="float-left w-70 genral_tab_content general_tabs_set">
            {this.state.loading && <OverlayLoader />}
            {/*this.invoiceStampContent()*/}
            {this.emailNotificationContent()}
            {this.salesAdminRecord()}
            {this.invoiceTermsContent()}
            {this.renderActionButtons()}
          </div>
        </div>
      </Form>
    );
  }

  render() {
    return this.renderStatusChangeInput();
  }
}

export default withRouter(InvoicePreference);
