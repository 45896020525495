import React, { Fragment, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withFormik, ErrorMessage } from 'formik';
import timezone from 'moment-timezone';
import {
  imagePathS3,
  restRequest,
  handleCity,
  handleStreet,
  handleState,
  handlePhone,
  findByValue,
  checkError,
} from '../../../Helpers';
import EmailInfo from '../../../Assets/General/EmailInfo';
import {
  required,
  ValidateName,
  ValidateRequired,
  ValidateEmail,
  // ValidatePhoneNumber,
  ValidateZipCode,
  ValidateMax200,
  ValidateMax100,
  ValidateMax20,
  ValidateMax60,
  ValidateWebsite,
} from '../../../Helpers/Validator';
import { validateBussinessForm } from '../../../Helpers/FormValidator';
import { Field, FormAction, Form, FormSection, ErrorFocus } from '../';
import FileUploader from '../../FileUploader/FileUploader';
import moment from 'moment/moment';
import { MdClose as CloseIcon } from '../../../Common/Icons';
import { CustomModal } from '../../UnsyncOrders';
import ImageCropper from '../../Cropper/ImageCropper';
import ImagePreview from '../../ImagePreview/ImagePreview';

const dateSeparators = [
  {
    label: '-',
    value: '-',
  },
  {
    label: '.',
    value: '.',
  },
  {
    label: '/',
    value: '/',
  },
];
let dateValueForFormat;
// const FILE_SIZE = 5000 * 1024

const formEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    organizationName: required(ValidateName),
    // organizationType: ValidateRequired.nullable(),
    fiscalYear: ValidateRequired.nullable(),
    organizationInventoryStartDate: ValidateRequired.nullable(),
    organizationBaseCurrency: ValidateRequired.nullable(),
    primaryContactName: ValidateMax100,
    primaryContactEmail: ValidateEmail,
    organizationStreet: ValidateMax200,
    organizationCity: ValidateMax100,
    organizationProvince: ValidateMax100,
    organizationWebsite: ValidateWebsite,
    phone: required(ValidateMax20),
    organizationPostalCode: ValidateZipCode,
    license_no: ValidateMax60.nullable(),
    // organizationLogo: Yup.mixed()
    //   .test(
    //     'fileSize',
    //     'File too large, Maximum size: 5 MB',
    //     value => !value.logo || (value.logo && value.logo.size <= FILE_SIZE)
    //   )
  }),
  mapPropsToValues: ({ organization }) => ({
    ...organization,
    showImageCropModal: false,
    organizationCountry: organization.organizationCountry
      ? organization.organizationCountry
      : 230,
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag);
  },
});

const getSelectedDateFormatt = (value, separator) => {
  const dateFormatOptions = getDateFormatOptionss(separator);
  return findByValue(value, dateFormatOptions);
};

const getDateFormatOptionss = (separator) => {
  const today = moment(dateValueForFormat);

  return [
    {
      label: 'default',
      options: [
        /*{
          value: 'medium1',
          label: `MM${separator}dd${separator}yyyy [${today.format('MM' + separator + 'DD' + separator + 'YYYY')}]`
        }*/
        {
          value: 'long6',
          label: `MMM DD, YY [${today.format('MMM DD, YY')}]`,
        },
      ],
    },
    {
      label: 'short',
      options: [
        {
          value: 'short1',
          label: `MM${separator}dd${separator}yy [${today.format(
            'MM' + separator + 'DD' + separator + 'YY'
          )}]`,
        },
        {
          value: 'short2',
          label: `dd${separator}MM${separator}yy [${today.format(
            'DD' + separator + 'MM' + separator + 'YY'
          )}]`,
        },
        {
          value: 'short3',
          label: `yy${separator}MM${separator}dd [${today.format(
            'YY' + separator + 'MM' + separator + 'DD'
          )}]`,
        },
      ],
    },
    {
      label: 'medium',
      options: [
        {
          value: 'medium1',
          label: `MM${separator}dd${separator}yyyy [${today.format(
            'MM' + separator + 'DD' + separator + 'YYYY'
          )}]`,
        },
        {
          value: 'medium2',
          label: `dd${separator}MM${separator}yyyy [${today.format(
            'DD' + separator + 'MM' + separator + 'YYYY'
          )}]`,
        },
        {
          value: 'medium3',
          label: `yyyy${separator}MM${separator}dd [${today.format(
            'YYYY' + separator + 'MM' + separator + 'DD'
          )}]`,
        },
      ],
    },
    {
      label: 'long',
      options: [
        {
          value: 'long1',
          label: `DD MMM YYYY [${today.format('DD MMM YYYY')}]`,
        },
        {
          value: 'long2',
          label: `DD MMMM YYYY [${today.format('DD MMMM YYYY')}]`,
        },
        {
          value: 'long3',
          label: `MMMM DD, YYYY [${today.format('MMMM DD, YYYY')}]`,
        },
        {
          value: 'long4',
          label: `ddd, MMMM DD, YYYY [${today.format('ddd, MMMM DD, YYYY')}]`,
        },
        {
          value: 'long5',
          label: `dddd, MMMM DD, YYYY [${today.format('dddd, MMMM DD, YYYY')}]`,
        },
        {
          value: 'long6',
          label: `MMM DD, YY [${today.format('MMM DD, YY')}]`,
        },
      ],
    },
  ];
};

const OrganizationForm = (props) => {
  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    errors,
    touched,
    // custom props
    history,
    // type,
    organizationTypes,
    fiscalYearFromDB,
    currencyOptions,
    // currencyEditable,
    handleToast,
    disable_currency,
    imagestate,
    handleChangeLogo,
  } = props;
  // dateValueForFormat = organizationInventoryStartDate._d

  const [staticVal, setStaticValues] = useState({});
  // const [CropimageModal, setCropimageModal] = useState(false);
  // const [cropimagefile, setcropimagefile] = useState();
  useEffect(() => {
    props.handleCrossAlert(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const {
    organizationName,
    organizationType,
    organizationTimezone,
    fiscalYear,
    organizationInventoryStartDate,
    organizationBaseCurrency,
    dateFormat,
    dateSeparator,
    organizationLogo,
    currentLogo,
    base84Imagem,
    license_no,
  } = values;

  const isBaseCurrencyNotChangable = disable_currency;
  // dateValueForFormat = organizationInventoryStartDate._d
  useEffect(() => {
    if (currentLogo != null) handleChangeLogo(`${imagePathS3(currentLogo)}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLogo]);
  const handleFiles = (files) => {
    const file = files[0];
    const allowedExtensions = [
      'image/png',
      'image/jpg',
      'image/jpeg',
      'image/webp',
    ];
    if (file && !allowedExtensions.includes(file.type)) {
      handleToast(' invalid file/file size.', 'error');
      return null;
    }
    // const allTypes = accepted.map((data) => data?.type);
    // const hasInvalidType = file?.some(
    //   (type) => !allowedExtensions.includes(type)
    // );

    // if (hasInvalidType) {
    //   handleToast(' invalid file/file size.', 'error');

    //   return null;
    // }
    if (!file) {
      handleToast('Invalid file/file size.', 'error');
      return;
    }

    let updatedFiles = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    handleChangeLogo(URL.createObjectURL(file));
    setFieldValue('organizationLogo', file, ...updatedFiles);
    setFieldValue('showImageCropModal', true);
    setFieldTouched('organizationLogo');
    // props.setPrompt();
  };

  const handleDelete = () => {
    handleChangeLogo('');
    setFieldValue('organizationLogo', null);
    setFieldTouched('organizationLogo');
    setFieldValue('base84Image', '');

    // props.setPrompt();
  };

  const handleChangeAlert = (e) => {
    handleChange(e);
  };

  const handleDropDownChangeAlert =
    (label, action = '', value, handleAction = () => {}) =>
    (option) => {
      if (action) {
        setFieldValue(label?.label || label, handleAction(option, value));
        // setFieldValue(label?.sub_label || label.sub_label, true);
      } else setFieldValue(label?.label || label, option ? option.value : null);
    };
  useEffect(() => {
    handleAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, staticVal]);

  useEffect(() => {
    setStaticValues({
      organizationName,
      organizationType,
      fiscalYear,
      organizationLogo,
      currentLogo,
      license_no,
      ...values,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAlert = () => {
    const { checkout } = validateBussinessForm(
      {
        organizationName,
        organizationType,
        fiscalYear,
        organizationLogo,
        currentLogo,
        license_no,
        ...values,
      },
      staticVal
    );
    if (!checkout) props.setPrompt(true);
    else props.setPrompt(false);
  };

  return (
    <div className="organization-form-set">
      <Form className="organization-form" onSubmit={handleSubmit}>
        <FormSection title="Basic Information">
          <div className="form-field">
            <div className="form-image">
              <FileUploader
                className="organization--img"
                accept="image/*"
                onDrop={handleFiles}
                maxSize={1 * 1000 * 1000}
                circular
                camIcon={true}
              />
              {!!imagestate && (
                <ImagePreview
                  isOrganization={true}
                  images={
                    values.organizationLogo?.length > 0
                      ? values.organizationLogo
                      : [imagestate]
                    // : []
                  }
                  onDeleteImage={handleDelete}
                />
              )}
              {ImageCropModal(props.values, setFieldValue, handleDelete)}
            </div>

            <input name="organizationLogo" className="input-zero" />
            <ErrorMessage
              name="organizationLogo"
              component="div"
              className="form--error-message"
            />
            <label className="form-label add_logo_title">Add Logo</label>
          </div>
          <div className="form-fields-container d-flex flex-wrap  position-relative w-100 ">
            <Field
              name="Name"
              placeholder="Business Name"
              value={organizationName}
              id="organizationName"
              className="flex-25"
              autoFocus={true}
              required
              onBlur={handleBlur}
              handleChange={handleChangeAlert}
              error={touched.organizationName && errors.organizationName}
            />
            <Field
              size="large"
              id="organizationType"
              type="dropdown"
              name="Type"
              placeholder="Business Type"
              value={organizationType}
              options={organizationTypes}
              dropdownValue="id"
              dropdownLabel="label"
              // required
              className="flex-25"
              error={touched.organizationType && errors.organizationType}
              onBlur={handleBlur}
              handleChange={handleDropDownChangeAlert('organizationType')}
            />
            <Field
              size="large"
              id="organizationTimezone"
              type="dropdown"
              name="Time Zone"
              className="flex-25"
              value={organizationTimezone}
              options={timezone.tz.names()}
              required
              clearable={false}
              handleChange={handleDropDownChangeAlert({
                label: 'organizationTimezone',
                sub_label: 'timezone_updated',
              })}
            />
            <Field
              size="large"
              id="fiscalYear"
              type="dropdown"
              name="Fiscal Year"
              value={fiscalYear}
              options={fiscalYearFromDB}
              dropdownValue="id"
              className="flex-25"
              dropdownLabel="fiscal_year"
              handleChange={handleDropDownChangeAlert('fiscalYear')}
              clearable={false}
              placeholder="Fiscal Year"
              onBlur={handleBlur}
              required
              error={touched.fiscalYear && errors.fiscalYear}
            />

            <Field
              disabled
              size="large"
              type="date"
              name="Inventory Start Date"
              id="organizationInventoryStartDate"
              className="flex-25"
              value={organizationInventoryStartDate}
              // DEV- commented bellow code because field is already disabled
              // onBlur={() => setFieldTouched('organizationInventoryStartDate')}
              // handleChange={(date) => {
              //   setFieldValue('organizationInventoryStartDate', date);
              // }}
              // error={
              //   touched.organizationInventoryStartDate &&
              //   errors.organizationInventoryStartDate
              // }
              // required
            />
            <div className="flex-25 form-field position-relative currency-field-main">
              <Field
                size="large"
                id="organizationBaseCurrency"
                type="dropdown"
                className="flex-100"
                name="Base Currency"
                value={organizationBaseCurrency}
                options={currencyOptions}
                dropdownValue="value"
                dropdownLabel="label"
                onBlur={handleBlur}
                handleChange={handleDropDownChangeAlert({
                  label: 'organizationBaseCurrency',
                  sub_label: 'base_currency',
                })}
                clearable={false}
                placeholder="Base Currency"
                required
                error={
                  touched.organizationBaseCurrency &&
                  errors.organizationBaseCurrency
                }
                disabled={isBaseCurrencyNotChangable}
              />
              <div
                title="This filed can't be edited once a SO, PO, Invoice, or Bill is created."
                className="currency_field orgination-currency"
              >
                <EmailInfo className="currnecy_info" />
              </div>
            </div>

            {isBaseCurrencyNotChangable && (
              <>
                <div className="flex-25" style={{ order: 1 }}></div>
                <div className="flex-50" style={{ order: 2 }}>
                  <h5 className="basecurrency-disabled-message">
                    *Base currency cannot be changed when there are transactions
                  </h5>
                </div>
              </>
            )}
            {renderOrganizationDateFormat(values, setFieldValue)}
            {!dateFormat.includes('long') && (
              <Field
                disabled={true}
                size="large"
                id="dateSeparator"
                className="flex-25"
                type="dropdown"
                name="Date Separator"
                value={dateSeparator}
                options={dateSeparators}
                dropdownValue="value"
                dropdownLabel="label"
                handleChange={handleDropDownChangeAlert('dateSeparator')}
                clearable={false}
              />
            )}
            <Field
              size="large"
              name="License No"
              placeholder="License No"
              value={license_no}
              id="license_no"
              className="flex-25"
              onBlur={handleBlur}
              handleChange={handleChangeAlert}
              error={touched.license_no && errors.license_no}
            />
          </div>
        </FormSection>
        <FormSection
          title="Contact Information"
          className="margin-top-lg contact-info-form"
        >
          {renderContactInformationFields({
            ...props,
            handleChangeAlert,
            handleDropDownChangeAlert,
          })}
        </FormSection>

        <FormAction
          marginFromRight={3.2}
          disabled={isSubmitting}
          onCancel={() => handleCancel(history)}
        />

        <ErrorFocus />
      </Form>
    </div>
  );
};

const ImageCropModal = (values, setFieldValue, handleDelete) => {
  var base64Images = [];
  return (
    <CustomModal
      className="upload_img_popup"
      showModal={values.showImageCropModal}
      title="Adjust Image"
      renderActions={false}
      onClose={() => {
        setFieldValue('showImageCropModal', false);
        handleDelete();
      }}
    >
      <ImageCropper
        inModal
        updatedFiles={[values.organizationLogo]}
        type="add"
        onSubmitModal={(updatedFiles, croppedImage) => {
          updatedFiles[updatedFiles.length - 1].preview = croppedImage;
          updatedFiles.forEach((el) => {
            base64Images.push(el.preview);
            setFieldValue('base84Image', base64Images);
          });
          setFieldValue('organizationLogo', updatedFiles);
          setFieldValue('showImageCropModal', false);
        }}
        closeModal={() => {
          setFieldValue('showImageCropModal', false);
          handleDelete();
        }}
      />
    </CustomModal>
  );
};

const renderOrganizationDateFormat = (values, setFieldValue) => {
  const { dateSeparator, dateFormat } = values;

  const options = getDateFormatOptionss(dateSeparator);
  return (
    <Field
      disabled
      size="large"
      id="dateFormat"
      className="flex-25"
      type="groupdropdown"
      name="Date Format"
      value={getSelectedDateFormatt(dateFormat, dateSeparator)}
      options={options}
      clearable={false}
    />
  );
};

const renderContactInformationFields = ({
  values: {
    primaryContactName,
    primaryContactEmail,
    organizationStreet,
    organizationCity,
    organizationProvince,
    organizationPostalCode,
    organizationCountry,
    phone,
    organizationWebsite,
  },
  // handleChange,
  handleChangeAlert,
  setFieldValue,
  handleBlur,
  errors,
  touched,
  countryList,
  handleDropDownChangeAlert,
  // countryStates,
  // populateCountryStates,
}) => {
  if (organizationPostalCode.toString().length > 15) {
    organizationPostalCode = organizationPostalCode.toString();
    organizationPostalCode = organizationPostalCode.slice(0, 15);
    // zipCode=parseInt(zipCode)
  }
  return (
    <Fragment>
      <div className="form-fields-container d-flex flex-wrap  position-relative w-100 ">
        <Field
          className="flex-25"
          name="Contact Name"
          size="large"
          placeholder="Contact Name"
          value={primaryContactName}
          id="primaryContactName"
          handleChange={handleChangeAlert}
          onBlur={handleBlur}
          error={touched.primaryContactName && errors.primaryContactName}
        />
        <Field
          className="flex-25"
          name="Contact Email"
          size="large"
          placeholder="Contact Email"
          value={primaryContactEmail}
          id="primaryContactEmail"
          handleChange={handleChangeAlert}
          onBlur={handleBlur}
          error={touched.primaryContactEmail && errors.primaryContactEmail}
        />

        <Field
          size="large"
          name="Street Address"
          className="flex-25"
          placeholder="Street Address"
          value={organizationStreet}
          id="organizationStreet"
          handleChange={handleDropDownChangeAlert(
            'organizationStreet',
            'street',
            200,
            handleStreet
          )}
          onBlur={handleBlur}
          error={touched.organizationStreet && errors.organizationStreet}
        />
        <Field
          name="City"
          size="large"
          placeholder="City"
          className="flex-25"
          value={organizationCity}
          id="organizationCity"
          handleChange={handleDropDownChangeAlert(
            'organizationCity',
            'city',
            100,
            handleCity
          )}
          onBlur={handleBlur}
          error={touched.organizationCity && errors.organizationCity}
        />
        <Field
          size="large"
          className="flex-25"
          id="organizationProvince"
          name="State / Province"
          placeholder="State / Province"
          value={organizationProvince}
          handleChange={handleDropDownChangeAlert(
            'organizationProvince',
            'province',
            100,
            handleState
          )}
          error={touched.organizationProvince && errors.organizationProvince}
        />
        <Field
          size="large"
          name="Zip Code"
          placeholder="Zip Code"
          className="flex-25"
          value={organizationPostalCode}
          id="organizationPostalCode"
          handleChange={handleChangeAlert}
          onBlur={handleBlur}
          onValueChange={handleDropDownChangeAlert('organizationPostalCode')}
          error={
            touched.organizationPostalCode && errors.organizationPostalCode
          }
        />
        <Field
          size="large"
          type="dropdown"
          name="Country"
          placeholder="Country"
          value={organizationCountry}
          options={countryList}
          className="flex-25"
          dropdownLabel="country_name"
          dropdownValue="id"
          required
          handleChange={handleDropDownChangeAlert({
            label: 'organizationCountry',
            sub_label: 'country_updated',
          })}
          clearable={false}
        />

        <Field
          size="large"
          name="Phone"
          className="flex-25"
          placeholder="Phone"
          value={phone}
          id="phone"
          handleChange={handleDropDownChangeAlert(
            'phone',
            'phone',
            20,
            handlePhone
          )}
          required
          onBlur={handleBlur}
          error={touched.phone && errors.phone}
        />
        <Field
          size="large"
          className="flex-25"
          name="Website"
          value={organizationWebsite}
          id="organizationWebsite"
          handleChange={handleChangeAlert}
          onBlur={handleBlur}
          error={touched.organizationWebsite && errors.organizationWebsite}
        />
      </div>
    </Fragment>
  );
};

const handleCancel = (history) => {
  history.push('/oc/organization/choose');
};

const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: {
      type,
      imagestate,
      history,
      handleToast,
      requestType,
      requestUrl,
      successMessage,
      onSubmit,
      organizationId,
      setPrompt,
    },
  }
) => {
  let data = new FormData();

  data.append('name', values.organizationName);
  data.append('organization_type_id', values.organizationType);
  data.append('fiscal_year_id', values.fiscalYear);
  data.append('company_street', values.organizationStreet);
  //data.append('company_province', StateFullName(values.organizationProvince))
  data.append('company_province', values.organizationProvince);
  data.append('company_postal_code', values.organizationPostalCode);
  data.append('company_city', values.organizationCity);
  values.organizationCountry &&
    data.append('country_id', values.organizationCountry);
  data.append('phone', values.phone);
  data.append('company_website', values.organizationWebsite);
  data.append(
    'inventory_start_date',
    values.organizationInventoryStartDate
      ? values.organizationInventoryStartDate.format('MM/DD/YYYY')
      : null
  );
  data.append('base_currency_id', values.organizationBaseCurrency);
  data.append('time_zone', values.organizationTimezone);
  data.append('primary_contact_name', values.primaryContactName);
  data.append('primary_contact_email', values.primaryContactEmail);
  data.append('date_format', values.dateFormat);
  data.append('date_separator', values.dateSeparator);
  data.append('license_no', values.license_no);
  // data.append(
  //   'logo',
  //   values.organizationLogo ? values.organizationLogo.preview : null
  // );
  data.append('base84Image', values.base84Image ?? '');

  data.append('is_logo_deleted', type === 'Edit' && !imagestate ? true : false);

  if (!values.organizationLogo) {
    data.delete('logo');
  }

  restRequest(requestType, requestUrl, data)
    .then((res) => {
      successMessage();
      setPrompt(false);
      window.onbeforeunload = () => null;
      window.location.href = '/oc/organization/choose';
    })
    .catch((error) => {
      checkError(error, handleToast);
      setSubmitting(false);
    });
};

export default formEnhancer(OrganizationForm);
