import React, { Component } from 'react';
import queryString from 'query-string';
import ContactIconListing from '../../Assets/General/ContactIconListing';
import ContactFlowDiagram from '../../Components/Onboarding/Diagrams/ContactFlowDiagram';
import { routesPath } from '../Navigation/NavigationUtils';
import { restRequest } from '../../Helpers/RequestHelper';
import AccessDenied from '../../Components/AccessDenied/AccessDenied';
import Alert from '../../Components/Alert/Alert';
import HeaderWrapper from '../../Components/HeaderWrapper/HeaderWrapper';
import ListTable from '../../Components/ListTable/ListTable';
import ListTableUserImageColumn from '../../Components/ListTable/ListTableUserImageColumn';
import CheckedMenu, {
  Button,
  Options,
  ButtonLink,
} from '../../Components/CheckedMenu/CheckedMenu';
import SearchResult from '../../Components/SearchBar/SearchResult';
import CustomModal from '../../Components/CustomModal/CustomModal';
import Export from '../../Components/Export/Export';
import {
  removeValueFromLS,
  getObjFromLS,
  setObjInLS,
  getStringValueFromLS,
} from '../../Helpers/LocalStorage';
import ContactDetails from '../../Pages/Contact/ContactDetails';
import AddContact from '../../Pages/Contact/AddContact';
import EditContact from '../../Pages/Contact/EditContact';
import EditIcon from '../../Assets/General/EditIcon';
import CloneIcon from '../../Assets/General/CloneIcon';
import EmailIcon from '../../Assets/General/EmailIcon';
import DeleteIcon from '../../Assets/General/DeleteIcon';
import ActiveIcon from '../../Assets/General/ActiveIcon';
import InactiveIcon from '../../Assets/General/InactiveIcon';
import { Link } from 'react-router-dom';
import ExportIcon from '../../Assets/General/ExportIcon';
import ImportIcon from '../../Assets/General/ImportIcon';
import Onboarding from '../../Components/Onboarding/InitialOnboardingScreen';
import EmptyTable from '../../Components/ListTable/EmptyTable';
import './Contact.css';
import { checkError } from '../../Helpers/AuthHelper';
import InfiniteScroll from 'react-infinite-scroll-component';
import ContentLoader from '../../Components/ContenLoader';
import Loader from '../../Components/Loader/Loader';
import { FastField } from 'formik';
import { orderBy } from 'lodash';
class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactId: null,
      data: [],
      dataExpanded: [],
      currentFilter: 'all',
      sortFilter: 'created_at',
      orderBy: 'desc',
      totalPage: 1,
      totalRecords: 0,
      currentPage: 1,
      viewPerPage: 20,
      search: '',
      showExportModal: false,
      selectedAlphabet: '',

      accessDenied: false,
      itemList: [],
      mypage: 1,
      next_page_url: null,
      contactInfo: [],
      base_currency: {},
      pageloading: false,
    };

    this.getID = this.getID.bind(this);
  }

  filterOptions = [
    {
      id: 'none',
      label: 'Status',
      type: 'tagline',
    },
    {
      id: 'all',
      label: 'All',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'all', page: 1 }),
    },
    {
      id: 'vendor',
      label: 'Vendors',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'vendor', page: 1 }),
    },
    {
      id: 'customer',
      label: 'Customers',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'customer', page: 1 }),
    },
    {
      id: 'active',
      label: 'Active',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'active', page: 1 }),
    },
    {
      id: 'inactive',
      label: 'Inactive',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'inactive', page: 1 }),
    },
  ];

  // sortOptions = [          //commenting this because we will be disabling sort options for contacts module
  //   {
  //     id: 'company_name',
  //     label: 'Company Name',
  //     type: 'link',
  //     onClick: () => this.generateRoute({ ...this.prepareSortFilter('company_name') })
  //   },
  //   {
  //     id: 'display_name',
  //     label: 'Name',
  //     type: 'link',
  //     onClick: () => this.generateRoute({ ...this.prepareSortFilter('display_name') })
  //   },
  //   {
  //     id: 'created_at',
  //     label: 'Created Time',
  //     type: 'link',
  //     onClick: () => this.generateRoute({ ...this.prepareSortFilter('created_at') })
  //   } ,
  //   /*{
  //     id: 'email',
  //     label: 'Email',
  //     type: 'link',
  //     onClick: () => this.generateRoute({ ...this.prepareSortFilter('email') })
  //   },
  //   {
  //     id: 'updated_at',
  //     label: 'Last Modified Time',
  //     type: 'link',
  //     onClick: () => this.generateRoute({ ...this.prepareSortFilter('updated_at') })
  //   }*/
  // ]

  sortOptions = [];
  hasCreatePermission() {
    const hasCreatePermission = this.hasPermission('contact', 'Create');
    return !hasCreatePermission;
  }

  importExportOptions = [
    {
      label: 'Import Contacts',
      icon: <ImportIcon />,
      onClick: () => {
        this.props.history.push('/contacts/import');
      },
      disabled: () => {
        return this.hasCreatePermission();
      },
    },
    {
      label: 'Export Contacts',
      icon: <ExportIcon />,
      onClick: () => this.openExportModal(),
      disabled: () =>
        !this.hasViewPermission('contact') ||
        JSON.parse(localStorage.getItem('exportBtn')),
    },
  ];

  componentDidMount() {
    document.title = 'Contacts';
    let selectedChar = localStorage.getItem('selectedChar');
    if (selectedChar) {
      this.setState(
        {
          ...this.state,
          selectedAlphabet: selectedChar,
        },
        () => {
          this.fetchData();
        }
      );
    } else {
      this.fetchDataWithQueryString();
    }
  }

  componentDidUpdate() {
    this.handleOnUpdateQueryString();
  }

  ResetPagination = ({ page = 1, char = '' }) => {
    // const { filter, page, sort, orderBy, view, search } = this.parseQueryString()
    this.setState(
      {
        mypage: 1,
        loading: true,
        selectedAlphabet: char,
        mainLoading: true,
      },
      () => {
        this.fetchData();
      }
    );
  };

  handleOnUpdateQueryString() {
    const { filter, page, sort, orderBy, view, search } =
      this.parseQueryString();

    if (filter !== this.state.currentFilter) {
      this.setState(
        {
          currentFilter: filter,
          mypage: 1,
          loading: true,
          mainLoading: true,
        },
        () => this.fetchData()
      );
    }
    if (sort !== this.state.sortFilter) {
      this.setState(
        {
          sortFilter: sort,
          loading: true,
          mypage: 1,
          mainLoading: true,
        },
        () => this.fetchData()
      );
    }
    if (orderBy !== this.state.orderBy) {
      this.setState(
        {
          orderBy: orderBy,
          mypage: 1,
          loading: true,
          mainLoading: true,
        },

        () => this.fetchData()
      );
    }
    if (page !== this.state.currentPage) {
      this.setState(
        {
          currentPage: parseInt(page),
          bulkSelectedData: [],
        },
        () => this.fetchData()
      );
    }
    if (view !== this.state.viewPerPage) {
      this.setState(
        {
          viewPerPage: parseInt(view),
          bulkSelectedData: [],
        },
        () => this.fetchData()
      );
    }
    if (search !== this.state.search) {
      this.setState({ search: search, mypage: 1, bulkSelectedData: [] }, () =>
        this.fetchData()
      );
    }
  }

  fetchDataWithQueryString() {
    const { filter, page, sort, orderBy, view, search } =
      this.parseQueryString();
    this.setState(
      {
        sortFilter: sort,
        currentFilter: filter,
        currentPage: parseInt(page),
        orderBy: orderBy,
        viewPerPage: parseInt(view),
        search: search,
      },
      () => this.fetchData('')
    );
  }

  parseQueryString() {
    const query = queryString.parse(this.props.location.search);
    let filter = '';
    switch (query.filter) {
      case 'all':
      case 'active':
      case 'inactive':
      case 'vendor':
      case 'customer':
        filter = query.filter;
        break;
      default:
        filter = 'all';
    }

    let sort = '';
    switch (query.sort) {
      case 'company_name':
      case 'display_name':
      // case 'email':
      // case 'created_at':
      // case 'updated_at':
      case 'payable':
      case 'receivable':
        sort = query.sort;
        break;
      default:
        sort = 'display_name';
    }

    let orderBy = '';
    switch (query.orderBy) {
      case 'asc':
      case 'desc':
        orderBy = query.orderBy;
        break;
      default:
        orderBy = 'asc';
    }

    let page = parseInt(query.page);
    if (!page) page = 1;
    let view = parseInt(query.view);
    if (!view) view = 20;

    let search = query.search;
    if (!search) search = '';

    return {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
    };
  }

  //  value = 'a';

  fetchData = () => {
    const {
      selectedAlphabet,
      mypage,
      orderBy,
      search,
      currentFilter,
      sortFilter,
    } = this.state;
    this.setState({
      pageloading: true,
      // mainLoading: true,
    });
    restRequest(
      'get',
      `contacts?${
        !selectedAlphabet ? '' : `searchByAlphabet=${selectedAlphabet}&`
      }contact_type=${currentFilter}&page=${mypage}&sort_by=${sortFilter}&order_by=${orderBy}&view=20&search=${search.replace(
        '&',
        '%26'
      )}`
    )
      .then((res) => {
        this.setState({
          pageloading: false,
          // mainLoading: false,
        });
        if (selectedAlphabet != '' && !res.contacts?.data?.length) {
          this.props.handleToast(
            'No contact found against this alphabet',
            'error'
          );
          localStorage.removeItem('selectedChar');
        }
        let dataFromLSContact = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLSContact,
          deleteContactRequest: false,
        });
        let obj = {
          prevId: null,
          id: res.contacts.data[0] && res.contacts.data[0].id,
          nextId: res.contacts.data[1] && res.contacts.data[1].id,
          moduleName: 'contacts',
          urlPath: window.location.pathname,
          queryParam: window.location.search,
        };
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS && dataFromLS.moduleName !== 'contacts') {
          sessionStorage.setItem('fullscreen', true);
          sessionStorage.setItem('once', true);
          removeValueFromLS('module');
          if (res.contacts.data[0]) {
            setObjInLS('module', obj);
          }
        }
        if (!res.contacts.data[0]) {
          removeValueFromLS('module');
        }
        if (!dataFromLS) {
          if (res.contacts.data[0]) {
            setObjInLS('module', obj);
          }
        } else if (dataFromLS.action === 'bulkdelete') {
          if (res.contacts.data[0]) {
            setObjInLS('module', { ...obj, lolo: 'no' });
          }
        } else if (
          dataFromLS &&
          dataFromLS.moduleName === 'contacts' &&
          (this.state.mypage === 1 ||
            dataFromLS.queryParam !== window.location.search)
        ) {
          if (res.contacts.data[0]) {
            setObjInLS('module', obj);
          }
        }
        setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 0);
        this.setState(
          {
            // data: this.prepareDataForTableCollapsed(
            //   [...this.state.data, ...res.contacts.data],
            //   res.base_currency
            // ),
            itemList:
              mypage === 1
                ? res.contacts.data
                : [...this.state.itemList, ...res.contacts.data],
            // contactInfo: res.contacts.data,
            // dataExpanded: this.prepareDataForTableExpanded(
            //   [...this.state.dataExpanded, ...res.contacts.data],
            //   res.base_currency
            // ),
            base_currency: res.base_currency,
            totalPage: res.contacts.last_page,
            totalRecords: res.contacts.total || 0,
            loading: false,
            next_page_url: res.contacts.next_page_url,
            mypage:
              res.contacts.last_page > this.state.mypage
                ? this.state.mypage + 1
                : this.state.mypage,
          },
          () => {
            this.setState({
              data: this.prepareDataForTableCollapsed(
                this.state.itemList,
                this.state.base_currency
              ),
              dataExpanded: this.prepareDataForTableExpanded(
                this.state.itemList,
                this.state.base_currency
              ),
              mainLoading: false,
              contactInfo: this.state.itemList,
            });
          }
        );
      })
      .catch((error) => {
        this.setState({
          pageloading: false,
        });
        let customErrorMsg =
          error && error.response.data.message
            ? error.response.data.message
            : 'An Error Occured';
        this.setState({
          accessDenied: checkError(
            error,
            this.props.handleToast,
            customErrorMsg
          ),
        });
      });
  };

  prepareDataForTableCollapsed(data, baseCurrency) {
    return data.map((contact) => ({
      id: contact.id,
      Name: {
        id: 'display_name',
        sortable: true,
        getValue: () => (
          <ListTableUserImageColumn
            image={contact.photo}
            name={contact.display_name}
          />
        ),
      },
      'Contact Type': {
        id: 'contact_type',
        getValue: function () {
          return (
            <div style={{ textTransform: 'capitalize' }}>
              {contact[this.id]}
            </div>
          );
        },
      },
      // not displayable
      additional: {
        isActive: contact.is_active,
      },
    }));
  }

  prepareDataForTableExpanded(data, baseCurrency) {
    return data.map((contact) => ({
      id: contact.id,
      Name: {
        id: 'display_name',
        sortable: true,
        market_place: contact.association,
        getValue: () => (
          <ListTableUserImageColumn
            image={contact.photo}
            name={contact.display_name}
          />
        ),
      },
      'Contact Type': {
        id: 'contact_type',
        getValue: function () {
          return (
            <div style={{ textTransform: 'capitalize' }}>
              {contact[this.id]}
            </div>
          );
        },
      },
      Company: {
        id: 'company_name',
        getValue: function () {
          return contact[this.id];
        },
      },

      Email: {
        id: 'email',
        getValue: function () {
          return contact[this.id];
        },
      },
      'Work Phone': {
        id: 'work_phone',
        getValue: function () {
          return contact[this.id] !== ''
            ? contact[this.id]
            : contact['work_phone_secondary'] !== ''
            ? contact['work_phone_secondary']
            : contact['mobile'] !== ''
            ? contact['mobile']
            : contact['mobile_secondary'];
        },
      },
      Receivable: {
        id: 'receivable',
        sortable: false,
        alignRight: true,
        getValue: function () {
          if (contact[this.id])
            return `${baseCurrency.symbol}${contact[this.id].toFixed(2)}`;
          return '';
        },
      },
      Payable: {
        id: 'payable',
        sortable: false,
        alignRight: true,
        getValue: function () {
          if (contact[this.id])
            return `${baseCurrency.symbol}${contact[this.id].toFixed(2)}`;
          return '';
        },
      },
      // not displayable
      additional: {
        isActive: contact.is_active,
      },
    }));
  }

  openExportModal() {
    this.setState({ showExportModal: true });
  }

  closeModal = () => {
    this.setState({ showExportModal: false });
  };

  getID(prevId, id, nextId) {
    let obj = {
      id,
      prevId: prevId ? prevId : null,
      nextId: nextId ? nextId : null,
      moduleName: 'contacts',
      urlPath: window.location.pathname,
      queryParam: window.location.search,
    };
    setObjInLS('module', obj);
    this.setState({ contactId: id });
  }

  renderExportModal() {
    return (
      <CustomModal
        showModal={this.state.showExportModal}
        title="Export Contacts"
        Icon={ExportIcon}
        onClose={this.closeModal}
        renderActions={false}
      >
        <Export
          name="Contacts"
          onCancel={this.closeModal}
          submitURL="contacts/export"
          handleToast={this.props.handleToast}
        />
      </CustomModal>
    );
  }

  // check status whether its active or not
  checkActiveStatus = (id) => {
    return this.state.data?.find((contact) => contact.id === id)?.additional
      .isActive;
  };

  exportCurrentView = () => {
    restRequest('post', `contacts/exportcurrentview`, {
      contact_ids: this.state.bulkSelectedData,
    })
      .then((res) => {
        let csvFile = 'data:text/csv;charset=utf-8,' + res;
        let encodedUri = encodeURI(csvFile);
        let link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `contacts.csv`);
        document.body.appendChild(link);
        link.click();
        this.props.handleToast(`contacts exported successfully`, 'success');
        this.setState({ bulkSelectedData: [] });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  };
  // render action menu for single selected data
  renderSingleActionMenu = () => {
    const id = this.state.bulkSelectedData[0];
    const isActive = this.checkActiveStatus(id);
    const [hasCreatePermission, hasEditPermission, hasDeletePermission] =
      this.hasPermission('contact', 'All');
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <ButtonLink
          to={`/contacts/edit/${id}`}
          enable={hasEditPermission}
          type="edit"
        />
        <ButtonLink
          to={`/contacts/clone/${id}`}
          enable={hasCreatePermission}
          type="clone"
        />
        <ButtonLink to={`/contacts/email/${id}`} type="email" />
        <Button
          enable={hasDeletePermission}
          handleClick={() =>
            this.openDeleteModal(
              id,
              this.props.forceRedirect,
              this.props.forceRedirectFlag
            )
          }
          type="delete"
        />
        <Button
          // label={`Mark as ${isActive ? 'inactive': 'active'}`}
          enable={hasEditPermission}
          handleClick={() =>
            this.toggleActive(id, !isActive ? 'active' : 'inactive')
          }
          type={isActive ? 'inactive' : 'active'}
        />
        <Button handleClick={() => this.exportCurrentView()} type="export" />
      </CheckedMenu>
    );
  };

  populateConditionalOptions() {
    const options = [
      {
        label: 'Mark as Active',
        onClick: () => this.toggleAllActive('active'),
      },
      {
        label: 'Mark as Inactive',
        onClick: () => this.toggleAllActive('inactive'),
      },
      {
        label: 'Export Selected',
        onClick: () => this.exportCurrentView(),
        disabled: () => !this.hasViewPermission('contact'),
      },
    ];
    return options;
  }

  // render action menu for multiple selected data
  renderMultipleActionMenu = () => {
    const hasDeletePermission = this.hasPermission('contact', 'Delete');
    const options = this.populateConditionalOptions();
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <Button
          enable={hasDeletePermission}
          handleClick={this.openBulkDeleteModal}
          type="delete"
        />
        <Options enable={!!options.length} options={options} />
      </CheckedMenu>
    );
  };

  hasEditPermission() {
    const hasEditPermission = this.hasPermission('contact', 'Edit');
    return hasEditPermission;
  }
  hasDeltePermission() {
    const hasDeltePermission = this.hasPermission('contact', 'Delete');
    return hasDeltePermission;
  }
  prepareRowOptions() {
    const rowOptions = [
      {
        label: 'Edit',
        type: this.hasEditPermission() && 'link',
        icon: EditIcon,
        onClick: (id) => `contacts/edit/${id}`,
        disabled: () => !this.hasEditPermission(),
      },
      {
        label: 'Clone',
        type: !this.hasCreatePermission() && 'link',
        icon: CloneIcon,
        onClick: (id) => `contacts/clone/${id}`,
        disabled: () => this.hasCreatePermission(),
      },
      {
        label: 'Email',
        type: 'link',
        icon: EmailIcon,
        onClick: (id) => `contacts/email/${id}`,
      },
      {
        label: 'Delete',
        icon: DeleteIcon,
        onClick: (id) => {
          const { attach_with_item = false, has_transaction = false } =
            this.state?.contactInfo?.find((f) => f.id === id);

          this.openDeleteModal(
            id,
            this.props.forceRedirect,
            this.props.forceRedirectFlag,
            attach_with_item || has_transaction,
            has_transaction
              ? 'Can not delete this contact as it is already in use.'
              : 'Vendor is linked with an item(s). You cannot delete this.'
          );
        },
        disabled: () => !this.hasDeltePermission(),
      },
      {
        label: 'Mark Inactive',
        icon: InactiveIcon,
        disabled: (id) =>
          !this.hasEditPermission() ? true : !this.checkActiveStatus(id),
        onClick: (id) => this.toggleActive(id, 'inactive'),
      },
      {
        label: 'Mark Active',
        icon: ActiveIcon,
        disabled: (id) =>
          !this.hasEditPermission() ? true : this.checkActiveStatus(id),
        onClick: (id) => this.toggleActive(id, 'active'),
      },
    ];

    return rowOptions;
  }

  deleteContectFromList = (id) => {
    this.setState({ mainLoading: true });

    const updadRecordList = this.state?.itemList?.filter(
      (record) => record.id !== id
    );
    this.setState(
      {
        data: this.prepareDataForTableCollapsed(
          updadRecordList,
          this.state.base_currency
        ),
        dataExpanded: this.prepareDataForTableExpanded(
          updadRecordList,
          this.state.base_currency
        ),
        itemList: updadRecordList,
        contactInfo: updadRecordList,
      },
      () => {
        this.setState({
          mainLoading: false,
        });
        let dataFromLssS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLssS,
          deletedid: null,
        });
      }
    );
  };

  renderTable() {
    const {
      data,
      itemList,
      dataExpanded,
      bulkSelectedData,
      sortFilter,
      orderBy,
      //loading,
      selectedAlphabet,
      accessDenied,
      mainLoading,
      totalRecords,
      pageloading,
    } = this.state;
    if (accessDenied) {
      return <AccessDenied type="section" />;
    }

    if (
      selectedAlphabet !== '' &&
      (itemList.length === 0 || data.length === 0) &&
      pageloading
    ) {
      return (
        <div>
          <EmptyTable />
          <Link to="/r" className="go_back">
            Go back to all Contacts
          </Link>
        </div>
      );
    }
    if (!mainLoading && !pageloading) {
      if (this.checkIfOnboarding()) {
        return (
          <Onboarding
            name="Contact"
            FlowDiagram={ContactFlowDiagram}
            videoFrame={
              <iframe
                width="700"
                height="500"
                src="https://www.youtube.com/embed/7fruK9bGEdI"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            }
            DisplayIcon={ContactIconListing}
            headerTitle="Contacts"
            description="Create and manage your customer and vendor contacts here"
            buttonLabel="Create Contacts"
            buttonLink="/contacts/add"
            secondaryButtonLabel="Import Contacts"
            secondaryButtonLink="/contacts/import"
          />
        );
      }
    }
    let fullscreen = getStringValueFromLS('fullscreen');
    let tableClassName = '';
    if (fullscreen === 'true') {
      tableClassName = dataExpanded.length <= 12 ? 'unset_heights' : '';
    } else {
      tableClassName = data.length <= 12 ? 'unset_heights' : '';
    }
    let dataFromLssS = getObjFromLS('module');
    // if (
    //   fullscreen &&
    //   dataFromLssS.deleteContactRequest &&
    //   dataFromLssS.moduleName === 'contacts' &&
    //   dataFromLssS.action === 'delete'
    // ) {
    //   this.fetchData();
    // }
    if (
      dataFromLssS.deletedid &&
      dataFromLssS.moduleName === 'contacts' &&
      dataFromLssS.action === 'delete'
    ) {
      this.deleteContectFromList(dataFromLssS?.deletedid);
    }

    return (
      <ListTable
        data={fullscreen === 'true' ? dataExpanded : data}
        className={`${tableClassName} ${
          fullscreen === 'true'
            ? ' fullscreen_contacts'
            : 'contact-list-compressed'
        }`}
        selectedData={bulkSelectedData}
        rowOptions={this.prepareRowOptions()}
        linkTo="/contacts"
        showAlphabet={true}
        sortFilter={sortFilter}
        orderBy={orderBy}
        loading={mainLoading || pageloading}
        isBlur="isActive"
        generateRoute={this.generateRoute}
        handleCheckAll={this.handleCheckAll}
        handleCheckSingle={this.handleCheckSingle}
        getID={this.getID}
        fetchData={this.fetchData}
        resetPagination={this.ResetPagination}
        isPageDefault={this.isPageDefault}
        moduleName="contacts"
        history={this.props.history}
        totalRecords={totalRecords}
        divClassName_rs={`contact-listing-main ${
          fullscreen === 'true'
            ? 'contact-listing-fullscreen'
            : 'contact-listing-compressed'
        }`}
        totalPage={this.state.totalPage}
        fetchDataWithQueryString={this.fetchDataWithQueryString}
        mypage={this.state.mypage}
        next_page_url={this.state.next_page_url}
      />
    );
  }

  renderSearchDetails() {
    const { search } = this.state;
    if (search) {
      return (
        <div className="contact-alert-active">
          <Alert
            className="full-width green"
            id="elasticSearch"
            title="Search Criteria"
            linkTo={routesPath.contacts}
          >
            <SearchResult
              criterias={[
                'Display Name',
                'First Name',
                'Last Name',
                'Company Name',
                //'Email',
                'Phone',
              ]}
              searchKey={search.replace('%26', '&')}
            />
          </Alert>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <>
        {this.renderExportModal()}
        {this.renderTable()}
      </>
    );
  }
}

export default HeaderWrapper(
  Contact,
  {
    name: 'Contacts',
    deleteName: 'Contact',
    Icon: ContactIconListing,
    tableIcon: 'contact_general_module_icon contact_top',
    baseUrl: 'contacts',
    buttonUrl: '/contacts/add',
    buttonLabel: 'New Contact',
    routeName: 'contacts',
    showDetailsComp: true,
    bypassLoadingState: false,
    permissionName: 'contact',
    pagination: false,
    showRecordsInfo: true,
  },
  ContactDetails,
  AddContact,
  EditContact
);

//handle loadings
