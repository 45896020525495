import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import { MdAdd } from '../../Common/Icons';
import { restRequest } from '../../Helpers/RequestHelper';
import getStatusColor from '../../Helpers/GetStatusColor';
import PaginationRoute from '../../Components/PaginationRoute/Pagination';
import Layout, { Body, Grid, Header } from '../../Components/Layout/Layout';
import OverlayLoader from '../../Components/Loader/OverlayLoader';
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import CustomModal from '../../Components/CustomModal/CustomModal';
import EmailInfo from '../../Assets/General/EmailInfo';
import {
  getStringValueFromLS,
  setObjInLS,
  getObjFromLS,
  removeValueFromLS,
} from '../../Helpers/LocalStorage';
import pageIdentify from '../../Helpers/PageIdentify';
import NotFound from './../NotFound/NotFound';
import Loader from '../Loader/Loader';
import { controllerNames } from '../../Constants';

import WithContext from '../../Context/UserContextHOC';
import './HeaderWrapper.css';
import ErrorBoundary from '../ErrorBoundry';
import { checkError, setDeploymentTime } from '../../Helpers/AuthHelper';
import useMobileDetection from '../../Hooks/MobileDetect';
import { array } from 'prop-types';
// import Alert from '../Alert/Alert';
// import { routesPath } from '../../Pages/Navigation/NavigationUtils';
// import SearchResult from '../SearchBar/SearchResult';

let redirectFlag = false;
let fromIntegration = window?.location?.href?.includes('un-sync-orders');
let focusSearchInput = false;

const singularNames = {
  Contacts: 'Contact',
  'Credit Notes': 'Credit Note',
  Items: 'Item',
  'Item Adjustments': 'Item Adjustment',
  'Item Groups': 'Item Group',
  'Sales Orders': 'Sales Order',
  'Sales Returns': 'Sales Return',
  Packages: 'Package',
  Invoices: 'Invoice',
  'Purchase Orders': 'Purchase Order',
  Bills: 'Bill',
  Taxes: 'Tax',
  Accounts: 'Account',
  'Delivery Methods': 'Delivery Method',
  'Payment Methods': 'Payment Method',
  Warehouses: 'Warehouse',
  Currencies: 'Currency',
  Payment: 'payment',
  'Payments Received': 'payment',
};

/**
 * @params Component, Object
 * note: must not use when user not logged in: throws error for role
 */
function HeaderWrapper(
  WrappedComponent,
  {
    name,
    subName,
    deleteName,
    tableIcon = '',
    showName = false,
    Icon = () => null,
    baseUrl = '',
    bulkDeleteUrl = '',
    buttonUrl = '',
    createModal = false,
    renderHeaderWrapper = true,
    // showCreateModal=()=>null,
    buttonLabel = '',
    redirectUrl = false,
    sectionHeader = true,
    pagination = true,
    showNewButton = true,
    onlyMenu,
    routeName = '',
    showDetailsComp = false,
    bypassLoadingState = false,
    permissionName = '',
    showAddTrue = false,
    showRecordsInfo = false,
    syncHistory = false,
  },
  SideDetailsComp,
  CreateModule,
  EditModule
) {
  class HeaderWrapperClass extends WrappedComponent {
    listRef = React.createRef();
    hasMobile = useMobileDetection();
    newFlag = false;
    state = {
      ...this.state,
      bulkSelectedData: [],
      showDeleteModal: false,
      bulkShowModal: false,
      modalId: '',
      expanded: false,
      deleteRedirect: () => {},
      deleteRedirectFlag: false,
      deploymentTime: false,
      selectedSyncIems: [],
      configItemData: {},
      deleteContactRequest: false,
      deleteStatus: false,
      deleteMessage: '',
      mainLoading: true,
      processing: false,
    };
    constructor(props) {
      super(props);
      this.forceRedirect = this.forceRedirect.bind(this);
      this.fetchDeployment();
    }

    // forceRedirect
    componentWillUnmount() {
      bypassLoadingState = false;
    }

    async fetchDeployment() {
      await restRequest('get', `deploymentTime`)
        .then((res) => {
          setDeploymentTime(res);
        })
        .catch((error) => {
          checkError(error, this.props.handleToast);
          this.setState({ loading: false });
        });
    }

    // @generate route for filters and others links
    generateRoute = ({
      filter = this.state.currentFilter,
      sort = this.state.sortFilter,
      orderBy = this.state.orderBy,
      page = this.state.currentPage,
      view = this.state.viewPerPage,
      search = this.state.search,
      contactId = this.state.contactId || '',
    }) => {
      if (syncHistory)
        routeName = this.props.location.pathname.split('').slice(1).join('');
      let path = '';
      if (filter)
        path = `/${routeName}?filter=${filter}&sort=${sort}&page=${page}&orderBy=${orderBy}&view=${view}`;
      if (!filter && view && page) {
        path = `/${routeName}?sort=${sort}&orderBy=${orderBy}&page=${page}&view=${view}`;
      }
      if (search) {
        path = `${path}&search=${search}`;
      }

      if (contactId) path = `${path}&contactId=${contactId}`;

      if (!filter && !view && !page)
        return `/${routeName}?sort=${sort}&orderBy=${orderBy}`;

      return path;
    };

    // @prepare sort filter based on order by
    prepareSortFilter(sortFilter) {
      const orderBy =
        this.state.sortFilter !== sortFilter
          ? 'desc'
          : this.state.orderBy === 'desc'
          ? 'asc'
          : 'desc';
      /*if(sortFilter === 'name' || sortFilter === 'display_name'){
         orderBy = 'asc'
      }*/
      return { sort: sortFilter, orderBy };
    }

    hasPermission(name, type) {
      const {
        context: {
          role: { permissions },
        },
      } = this.props;
      if (permissions.superAccess) {
        if (type === 'All') return [true, true, true];
        return true;
      }

      if (!['Create', 'Edit', 'Delete', 'All'].includes(type))
        throw new Error('Type must be one of Create, Edit, Delete, All');
      if (!(name in controllerNames))
        throw new Error('Invalid name is receieved for permission check');

      if (type !== 'All') {
        return permissions[`${controllerNames[name]}${type}`];
      }

      return [
        permissions[`${controllerNames[name]}Create`],
        permissions[`${controllerNames[name]}Edit`],
        permissions[`${controllerNames[name]}Delete`],
      ];
    }
    hasViewPermission(name) {
      const {
        context: {
          role: { permissions },
        },
      } = this.props;
      if (permissions.superAccess) {
        return true;
      }
      if (!(name in controllerNames))
        throw new Error('Invalid name is receieved for permission check');
      return permissions[`${controllerNames[name]}View`];
    }

    handleCheckAll = (event) => {
      let bulkSelectedData = [];
      let selectedSyncIems = [];
      // deselect checkbox
      if (
        this.state.bulkSelectedData.length !== 0 &&
        this.state.data.length !== this.state.bulkSelectedData.length
      ) {
        this.setState({
          bulkSelectedData,
          selectedSyncIems,
        });
      } else {
        if (event.target.checked) {
          if (name === 'Sync History') {
            bulkSelectedData = this.state.data.map((single) => single.id);
            // M--Starting
            const { selectedData = [] } = this.state.configItemData;

            selectedData.forEach((item) => {
              selectedSyncIems.push(item.rowIndex);
            });
            // M--Ending
          } else {
            bulkSelectedData = this.state.data.map((single) => single.id);
          }
        }
        this.setState({ bulkSelectedData, selectedSyncIems: selectedSyncIems });
      }
    };

    handleCheckSingle = (event) => {
      let tempSyncItems = this.state.selectedSyncIems;
      const tempCheckedData = this.state.bulkSelectedData;
      const dataID = parseInt(event.target.name);
      if (name === 'Sync History') {
        // M--Starting
        const { selectedData = [] } = this.state.configItemData;
        let cloneSelectedData = [...selectedData];

        cloneSelectedData = cloneSelectedData.filter(
          (item) => item.mainrowId === dataID
        );
        if (event.target.checked) {
          cloneSelectedData.forEach((item) => {
            tempSyncItems.push(item.rowIndex);
          });
        } else {
          cloneSelectedData.forEach((item) => {
            const index = tempSyncItems.indexOf(item.rowIndex);
            if (index > -1) {
              tempSyncItems.splice(index, 1);
            }
          });
        }
        this.setState({
          selectedSyncIems: tempSyncItems,
        });
        // M--Ending
      }

      if (tempCheckedData.includes(dataID)) {
        tempCheckedData.splice(tempCheckedData.indexOf(dataID), 1);
      } else {
        tempCheckedData.push(dataID);
      }

      this.setState({
        bulkSelectedData: tempCheckedData,
      });
    };

    handleResetCheckbox = (reset = false) => {
      let bulkSelectedData = [];
      if (reset) {
        this.setState({ bulkSelectedData });
      }
    };

    // toggle bulk active contact
    toggleAllActive = (marker) => {
      this.setState({
        processing: true,
      });
      restRequest('put', `${baseUrl}/${marker}`, {
        ids: this.state.bulkSelectedData,
      })
        .then((res) => {
          this.setState({ processing: false });
          this.fetchData();
          this.updateCheckedState();
          this.forceRedirect();
          if (marker === 'active')
            this.props.handleToast('Marked as active', 'success');
          else if (marker === 'inactive')
            this.props.handleToast('Marked as inactive', 'success');
        })
        .catch((error) => {
          this.setState({ processing: false });
          checkError(error, this.props.handleToast);
          //this.props.handleToast(error, "error")
        });
    };

    // toggle active contact
    toggleActive = (id, marker) => {
      restRequest('put', `${baseUrl}/${id}/${marker}`)
        .then(() => {
          this.fetchData();
          this.updateCheckedState();
          this.forceRedirect();
          if (marker === 'active')
            this.props.handleToast('Marked as active', 'success');
          else this.props.handleToast('Marked as inactive', 'success');
        })
        .catch((error) => {
          checkError(error, this.props.handleToast);
          //this.props.handleToast(error, "error")
        });
    };

    closeDeleteModalFunction = () => {
      this.setState({
        showDeleteModal: false,
        bulkShowModal: false,
      });
    };

    deleteConfirm = (id) => {
      this.setState({
        loading: true,
      });
      sessionStorage.setItem('fullscreen', true);
      sessionStorage.setItem('fullscreenFlag', true);
      // this.closeDeleteModal();
      this.closeDeleteModalFunction();
      let lc = window.location.pathname;
      restRequest('delete', `${baseUrl}/${id}`)
        .then((response) => {
          this.setState({ loading: false });
          let dataFromLS = getObjFromLS('module');
          if (dataFromLS) {
            let obj = {
              prevId: null,
              id:
                dataFromLS.id !== id
                  ? dataFromLS.id
                  : dataFromLS.prevId
                  ? dataFromLS.prevId
                  : dataFromLS.nextId
                  ? dataFromLS.nextId
                  : null,
              nextId: dataFromLS.prevId !== null ? dataFromLS.nextId : null,
              moduleName: baseUrl,
              urlPath: lc,
              queryParam: window.location.search,
              action: 'delete',
              deleteContactRequest: true,
            };
            if (obj.moduleName === 'contacts' && obj.action === 'delete') {
              setObjInLS('module', obj);
              const updadRecordList = this.state?.itemList?.filter(
                (record) => record.id !== id
              );
              if (!updadRecordList) {
                this.setState({ mainLoading: true });
                let conactdataFromLS = getObjFromLS('module');
                setObjInLS('module', {
                  ...conactdataFromLS,
                  deletedid: id,
                });
                if (response.message) {
                  this.props.handleToast(`${response.message}`, 'success');
                } else {
                  this.props.handleToast(
                    `${name} deleted successfully`,
                    'success'
                  );
                }
                this.props.history.push('/contacts');
              }
              this.setState(
                {
                  data: this.prepareDataForTableCollapsed(
                    updadRecordList,
                    this.state.base_currency
                  ),
                  dataExpanded: this.prepareDataForTableExpanded(
                    updadRecordList,
                    this.state.base_currency
                  ),
                  itemList: updadRecordList,
                  mainLoading: false,
                  contactInfo: updadRecordList,
                },
                () => {
                  this.props.handleToast(response.message, 'success');
                  this.props.history.push('contacts');
                }
              );
              if (response.message) {
                this.props.handleToast(`${response.message}`, 'success');
              } else {
                this.props.handleToast(
                  `${name} deleted successfully`,
                  'success'
                );
              }
              return;
            }
            setObjInLS('module', obj);
          } else {
            let obj = {
              prevId: null,
              id: dataFromLS.prevId
                ? dataFromLS.prevId
                : dataFromLS.nextId
                ? dataFromLS.nextId
                : null,
              nextId: dataFromLS.prevId !== null ? dataFromLS.nextId : null,
              moduleName: baseUrl,
              urlPath: lc,
              queryParam: window.location.search,
              action: 'delete',
              deleteContactRequest: true,
            };
            setObjInLS('module', obj);
          }
          this.state.deleteRedirect();
          // The following line has been written because the component was not being unmounted because in the start of this fucntion we have set fullscreen to true.
          // if(lc === '/contacts' || lc === '/items' || lc === '/item-adjustments') this.props.history.push('/r')
          if (
            dataFromLS.moduleName !== 'deliveryMethods' ||
            'paymentmethods' ||
            '/taxes'
          ) {
            this.props.history.push('/r');
            this.setState({
              deleteRedirect: () => {},
              deleteRedirectFlag: false,
            });
            if (window.location.search && redirectUrl) {
              this.props.history.push(redirectUrl + window.location.search);
            } else if (redirectUrl) {
              this.props.history.push(redirectUrl);
            } else {
              this.fetchData();
            }
          }
          if (response.message) {
            this.props.handleToast(`${response.message}`, 'success');
          } else {
            this.props.handleToast(`${name} deleted successfully`, 'success');
          }
          // this.closeDeleteModal();
        })
        .catch((error) => {
          var err_msg = error?.response?.data?.message
            ? error?.response?.data?.message
            : 'An error occured';
          this.closeDeleteModal();
          checkError(error, this.props.handleToast, err_msg);
        });
    };

    bulkDeleteConfirm = () => {
      // this.closeDeleteModal();
      this.closeDeleteModalFunction();

      var totalDeletedItems = this.state.bulkSelectedData.length;
      restRequest('delete', baseUrl, {
        ids: this.state.bulkSelectedData,
      })
        .then((response) => {
          // TODO: shoule be fixed later
          let obj = {
            prevId: null,
            id: null,
            nextId: null,
            moduleName: baseUrl,
            urlPath: window.location.pathname,
            queryParam: window.location.search,
            action: 'bulkdelete',
          };

          if (baseUrl === 'items') {
            var currentPage = sessionStorage.getItem('current_page');
            //var currentView = sessionStorage.getItem('view')
            var total_items = sessionStorage.getItem('total_items');
            var totalLeftItems =
              parseInt(total_items) - parseInt(totalDeletedItems);
            //console.log('currentPage', parseInt(total_items),totalDeletedItems,totalLeftItems)
            if (totalLeftItems === 0) {
              if (parseInt(currentPage) !== 1) {
                //console.log('i m here')
                var replaceString = '&page=' + currentPage;
                var newPageNo = '&page=' + (currentPage - 1);
                var str = window.location.search;
                var resp = str.replace(replaceString, newPageNo);
                this.props.history.push(window.location.pathname + resp);
              } else if (parseInt(currentPage) === 1) {
                this.props.history.push(window.location.pathname);
              }
            }
          }
          setObjInLS('module', obj);
          if (bulkDeleteUrl === 'item/bulkDelete') {
            if (response.length) {
              this.props.handleToast(
                `${name}s deleted successfully`,
                'success'
              );
            } else {
              this.props.handleToast(
                `You cannot delete these ${name}s, some are already in use`,
                'error'
              );
            }
            this.fetchData();
          } else {
            this.props.handleToast(response.message, 'success');
            if (obj.moduleName === 'contacts') {
              sessionStorage.setItem('fullscreen', true);
              sessionStorage.setItem('once', true);
              this.setState(
                {
                  mypage: 1,
                  itemList: [],
                  data: [],
                  expanded: [],
                },
                () => {
                  this.fetchData();
                }
              );
            }
          }
          this.closeDeleteModal();
        })
        .catch((error) => {
          this.closeDeleteModal();
          checkError(error);
          this.props.handleToast(error ? error : 'An error occured', 'error');
        });
    };
    openDeleteModal = (
      id,
      redirect,
      flag,
      deleteStatus = false,
      deleteMessage = ''
    ) => {
      this.setState({
        showDeleteModal: true,
        modalId: id,
        deleteStatus,
        deleteMessage,
      });
      if (flag) {
        this.setState({
          deleteRedirect: redirect,
          deleteRedirectFlag: flag,
        });
      }
    };

    openBulkDeleteModal = () => {
      this.setState({ bulkShowModal: true });
    };

    closeDeleteModal = () => {
      this.setState({
        showDeleteModal: false,
        bulkShowModal: false,
      });
      this.fetchData(); // WARNING: check later
      this.updateCheckedState();
    };

    // do not reset checked datas
    closeDeleteModalFromModal = () => {
      this.setState({
        showDeleteModal: false,
        bulkShowModal: false,
      });
    };

    renderBulkDeleteModal(event) {
      return (
        <CustomModal
          type="delete"
          showModal={this.state.bulkShowModal}
          title={`Confirm Delete`}
          onClose={this.closeDeleteModalFromModal}
          onSubmit={this.bulkDeleteConfirm}
        >
          {`Are you sure you want to delete ${
            this.state.bulkSelectedData.length
          } ${deleteName}${
            this.state.bulkSelectedData.length > 1 ? 's' : ''
          } ?`}
        </CustomModal>
      );
    }

    renderDeleteModal() {
      return (
        <CustomModal
          type="delete"
          showModal={this.state.showDeleteModal}
          title={`Confirm Delete`}
          onClose={this.closeDeleteModalFromModal}
          onSubmit={() => {
            if (this.state.deleteStatus) {
              this.props.handleToast(this.state.deleteMessage, 'error');
              this.closeDeleteModalFromModal();
            } else {
              this.setState(
                {
                  deleteContactRequest: true,
                },
                () => {
                  this.deleteConfirm(this.state.modalId);
                }
              );
            }
          }}
        >
          {`Are you sure you want to delete this ${
            subName ? subName : singularNames[name]
          }?`}
        </CustomModal>
      );
    }

    // check and render for multiple or single data
    renderCheckedMenu = () => {
      /*const isSingle = this.state.bulkSelectedData.length < 2
      if (isSingle) {
        return this.renderSingleActionMenu()
      }*/
      return this.renderMultipleActionMenu();
    };

    updateCheckedState = () => {
      this.setState({
        bulkSelectedData: [],
      });
    };

    checkIfOnboarding() {
      const { data, loading } = this.state;
      const { location } = this.props;

      let dataFromLS = getObjFromLS('module');
      return (
        !loading &&
        !data.length &&
        !(dataFromLS && dataFromLS.action && dataFromLS.action === 'add') &&
        //above line added to handle onboarding screen appearing after creating new form from all modules. This is for testing purpose.
        !(dataFromLS && dataFromLS.action && dataFromLS.action === 'delete') &&
        // (dataFromLS ? dataFromLS.action !== "delete" : true) &&
        isEmpty(queryString.parse(location.search))
      );
    }

    checkIfHeaderIsShown() {
      const { loading, currentFilter, data = [] } = this.state;
      return (!data.length || loading || !sectionHeader) && !currentFilter;
      // return (
      //   (!data.length || loading || !sectionHeader) &&
      //   (!currentFilter || currentFilter === 'all')
      // );
    }

    renderHeader = () => {
      if (syncHistory) return this.renderPagination();
      const {
        bulkSelectedData,
        notFound,
        sortFilter,
        orderBy,
        currentFilter,
        // deploymentTime,
      } = this.state;
      if (this.checkIfOnboarding()) return null;
      if (this.checkIfHeaderIsShown()) return null;

      if (notFound) return <NotFound />;

      if (onlyMenu) return this.renderCheckedMenu();
      let fullscreen = getStringValueFromLS('fullscreen');
      return (
        <div
          className={`section-header--pagination ${
            name === 'Contacts' ? 'contact-header-pagination' : ''
          }`}
        >
          {bulkSelectedData.length ? (
            this.renderCheckedMenu()
          ) : (
            <>
              <Icon className={`${tableIcon} header-wrapper-icon`} />
              <span className="float-left semi-bold hw-name">
                {fullscreen === 'true' && name}
              </span>
              <SectionHeader
                filterOptions={this.filterOptions}
                sortOptions={this.sortOptions}
                imExOptions={this.importExportOptions}
                currentFilter={currentFilter}
                sortFilter={sortFilter}
                orderBy={orderBy}
                buttonUrl={buttonUrl}
                buttonLabel={buttonLabel}
              />
              <div className="header_right_Sec float-right">
                {showNewButton &&
                fullscreen === 'true' &&
                this.hasPermission(permissionName, 'Create') ? (
                  <>
                    {createModal ? (
                      <button
                        id="Click_Add_New_Item_Button"
                        onClick={() => this.showCreateModal()}
                        className="button--primary float-left mr-3"
                        // id="Click_Add_New_Item_Button-01"
                      >
                        <MdAdd />
                        New
                      </button>
                    ) : (
                      <button
                        id="Click_Add_New_Item_Adjustment_Button"
                        onClick={() => this.props.history.push(`${buttonUrl}`)}
                        className="button--primary float-left mr-3"
                      >
                        <MdAdd />
                        New
                      </button>
                    )}
                  </>
                ) : null}
                {/* Moved Pagination here in order to create space for bulk actions */}
                {pagination && this.renderPagination()}
                {name === 'Contacts' && showRecordsInfo && (
                  <div className="header-contact-info">
                    <EmailInfo className="contact_info_header_icon" />
                    <div className="totalcount4">{`Total Contacts : ${
                      this.state && this.state.totalRecords
                        ? this.state.totalRecords
                        : 0
                    }`}</div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      );
    };

    renderPagination() {
      var records =
        this.state && this.state.totalRecords ? this.state.totalRecords : 0;
      var route =
        routeName && routeName === 'packages' ? 'package_listing' : routeName;
      return (
        <PaginationRoute
          viewPerPage={this.state.viewPerPage}
          totalPage={this.state.totalPage}
          currentPage={this.state.currentPage}
          generateRoute={this.generateRoute}
          history={this.props.history}
          totalRecords={records}
          listing_name={route}
          className={syncHistory ? 'sync-history-pagination' : ''}
          isIntegration={fromIntegration ? true : false}
        />
      );
    }

    renderHeaderTitle() {
      return (
        <span className="section-header--title">
          <Icon />
          {name}
        </span>
      );
    }

    showStatus() {
      let show = false;
      let status = '';
      if (this.state.status) {
        show = true;
        status = this.state.status;
      } else if (this.state.package) {
        show = true;
        status = this.state.package.status;
      } else if (this.state.purchaseOrderInfo) {
        show = true;
        status = this.state.purchaseOrderInfo.status;
      } else if (this.state.invoiceInfo) {
        show = true;
        status = this.state.invoiceInfo.status;
      } else if (this.state.salesOrder) {
        show = true;
        status = this.state.salesOrder.status;
      } else if (this.state.billInfo) {
        show = true;
        status = this.state.billInfo.status;
      }
      //console.log('window.location.pathname', window.location.pathname)
      return (
        <Fragment>
          {show &&
            window.location.pathname.includes('/salesreturns') === true && (
              <span
                className={`${
                  status === 'receiving'
                    ? getStatusColor('partially received')
                    : status === 'closed'
                    ? getStatusColor('received')
                    : getStatusColor(status)
                } status-placement-hw`}
              >
                {status === 'receiving'
                  ? 'PRTL Received'
                  : status === 'closed'
                  ? 'Received'
                  : status}
              </span>
            )}
          {show &&
            window.location.pathname.includes('/salesreturns') === false && (
              <span className={`${getStatusColor(status)} status-placement-hw`}>
                {status === 'partially paid'
                  ? 'PRTL Paid'
                  : status === 'partially applied'
                  ? 'PRTL Applied'
                  : status}
              </span>
            )}
        </Fragment>
      );
    }
    renderDetailsHeaderTitle() {
      return (
        <span className="section-header--title">
          <span className="section-header--title-icon">
            {' '}
            <Icon />{' '}
          </span>
          <span> {pageIdentify(window.location.pathname) && name} </span>
          <span>
            {' '}
            {this.state.detailsNo &&
              pageIdentify(window.location.pathname) &&
              '/'}{' '}
            <span className="semi-bold">{this.state.detailsNo}</span>{' '}
          </span>
          {this.showStatus()}
        </span>
      );
    }
    checkSetting() {
      let lc = window.location.pathname;
      if (
        lc.includes('taxes') ||
        lc.includes('accounting') ||
        lc.includes('deliveryMethods') ||
        lc.includes('paymentmethods') ||
        lc.includes('currencies') ||
        lc.includes('preference') ||
        lc.includes('warehouses')
      ) {
        removeValueFromLS('module');
        return true;
      } else {
        return false;
      }
    }
    renderRightHeaderContent() {
      return (
        // onlyMenu ?
        <div
          className={`center actions-menu--holder ${
            window.innerWidth <= 1100
              ? 'mobile-workig mobile-menu-main '
              : 'not-moblile'
          }    ${onlyMenu ? 'details-headers' : ''} `}
          style={{ position: 'relative' }}
        >
          {this.state.detailsNo && showName && (
            <Icon className={`icon_in_hw header-wrapper-icon ${tableIcon}`} />
          )}
          <p
            className={
              name === 'Item Adjustments'
                ? 'name_in_hw item-adjustment'
                : 'name_in_hw'
            }
          >
            {this.state.detailsNo && showName && this.state.detailsNo}
          </p>
          {this.showStatus()}

          {this.renderActionMenu ? this.renderActionMenu() : null}
          {createModal && showAddTrue && (
            <button
              onClick={() => this.showCreateModal()}
              className="button--primary float-left mr-3"
            >
              <MdAdd />
              New
            </button>
          )}
        </div>
      );
    }

    // renderSearchDetails = () => {
    //   const { search, contactId, contactName } = this.state;
    //   console.log({ search, contactId, contactName });
    //   if (search) {
    //     return (
    //       <Alert
    //         className="full-width green"
    //         id="elasticSearch"
    //         title="Search Criteria"
    //         linkTo={routesPath.salesOrders}
    //       >
    //         <SearchResult
    //           criterias={['Sales Order No', 'Customer Name', 'Reference']}
    //           searchKey={search.replace('%26', '&')}
    //         />
    //       </Alert>
    //     );
    //   }
    //   if (contactId) {
    //     return (
    //       <Alert
    //         className="full-width green"
    //         id="elasticSearch"
    //         title="Sales Orders"
    //         linkTo={routesPath.salesOrders}
    //         crossIcon={false}
    //         //onClickHandler={this.onClickHandler}
    //       >
    //         Displaying all results from contact "{contactName}"
    //       </Alert>
    //     );
    //   }
    //   return null;
    // };

    renderBodyContent() {
      let lc = window.location.pathname;
      let flag = lc.includes('organization');
      if (this.state.loading) {
        return <Loader />;
      }
      if (onlyMenu) {
        if (!pageIdentify(window.location.pathname)) {
          return (
            <div
              className="detail_component_inner detais-component-main"
              style={{
                float: `${!flag ? 'left' : ''}`,
                width: `${!flag ? '100%' : ''}`,
              }}
            >
              {super.render()}
            </div>
          );
        } else {
          return (
            // give class to div beacuse of tabs section in top in detail view
            <div className="position-relative">
              <Body>{super.render()}</Body>
            </div>
          );
        }
      }
      return (
        <div
          id={`${
            !flag
              ? this.checkIfOnboarding()
                ? 'onboarding-screen'
                : 'listing_component'
              : ''
          }`}
        >
          {/* {this.renderSearchDetails()} */}
          {super.renderSearchDetails && super.renderSearchDetails()}
          <Grid className="no-height">
            {renderHeaderWrapper && this.renderHeader()}
            {super.render()}
          </Grid>
        </div>
      );
    }
    forceRedirect() {
      this.setState({ loading: false });
      redirectFlag = true;
    }
    detailComponent() {
      if (syncHistory) return true;
      let value = getObjFromLS('module');
      let fromLS = getObjFromLS('module');
      let fullscreen = getStringValueFromLS('fullscreen');
      let once = sessionStorage.getItem('once');
      if (fullscreen === 'false' && once === 'true') {
        bypassLoadingState = true;
        sessionStorage.setItem('once', false);
      }
      if (value) {
        value = value.id;
      }
      if (redirectFlag) {
        return (
          <>
            <Redirect to="/r" />
            {(redirectFlag = false)}
          </>
        );
      }

      if (this.state.test !== value) {
        this.newFlag = false;
        this.setState({ test: value, loading: true });
        setTimeout(() => {
          if (bypassLoadingState === true) {
            this.setState({ loading: false });
          }
          bypassLoadingState = true;
        }, 0);
      }
      if (this.state.loading) {
        return null;
      }
      if (this.newFlag) {
        return (
          <div
            className={`detail_component ${
              this.checkIfOnboarding() ? 'paddinggg' : ''
            }`}
          >
            <CreateModule
              idFromHeaderWrapper={value}
              handleToast={this.props.handleToast}
              forceRedirect={this.forceRedirect}
              forceRedirectFlag={true}
              history={this.props.history}
            />
          </div>
        );
      }
      if (fromLS && fromLS.action === 'edit') {
        return (
          <div className="detail_component">
            <EditModule
              idFromHeaderWrapper={value}
              handleToast={this.props.handleToast}
              forceRedirect={this.forceRedirect}
              forceRedirectFlag={true}
              history={this.props.history}
            />
          </div>
        );
      }
      if (value) {
        return (
          <div className="detail_component">
            <ErrorBoundary>
              <SideDetailsComp
                idFromHeaderWrapper={value}
                handleToast={this.props.handleToast}
                forceRedirect={this.forceRedirect}
                forceRedirectFlag={true}
                history={this.props.history}
              />
            </ErrorBoundary>
          </div>
        );
      } else return <></>;
    }

    renderPage() {
      const settingModule = [
        'taxes',
        'deliverymethods',
        'warehouses',
        'accounting',
        'currencies',
        'preference',
        'paymentmethods',
      ];
      const urlpathArray = window.location.pathname.split('/');
      let fullscreen = getStringValueFromLS('fullscreen');
      let settingscreenflag = settingModule.includes(urlpathArray[1]);
      let dataFromLS = getObjFromLS('module');
      const { presistDetailPage = false } = dataFromLS;
      if (presistDetailPage && this.state.data && !settingscreenflag)
        sessionStorage.setItem('fullscreen', false);
      let renderHeaderWrapperAll =
        window.location.pathname === redirectUrl ||
        '/' + window.location.pathname.split('/')[1] === redirectUrl
          ? true
          : false;

      if (fullscreen === 'true' && !pageIdentify(window.location.pathname)) {
        return (
          <Layout className={`${onlyMenu ? 'details' : ''}`}>
            <div className={`${fullscreen ? 'fullscreenyes' : 'fullscreenno'}`}>
              {showAddTrue && (
                <Header
                  title={
                    onlyMenu
                      ? this.renderDetailsHeaderTitle()
                      : this.renderHeaderTitle()
                  }
                >
                  {this.renderRightHeaderContent()}
                </Header>
              )}
              {this.renderBodyContent()}
            </div>
          </Layout>
        );
      }
      // const searchElement = document.getElementById("realTimeSearch");
      // setTimeout(() => {
      //   searchElement.focus();
      // }, 0);
      return (
        <Fragment>
          <Layout className={`${onlyMenu ? 'details' : ''}`}>
            {renderHeaderWrapperAll && this.renderRightHeaderContent()}
            <div
              className={`${
                showDetailsComp
                  ? `showDetailsComponent parent-component ${
                      (window.location.pathname === '/items' || '/contacts') &&
                      this.state.search &&
                      'elastic_search_result'
                    }`
                  : ''
              }`}
            >
              {this.renderBodyContent()}
              {showDetailsComp && this.detailComponent()}
            </div>
          </Layout>
        </Fragment>
      );
    }

    render() {
      //console.log('--------------- ', this.props)
      //const { history: { location: { search } } } = this?.props;
      // console.log(search)
      // To show the header the in organizations tab. Temp Fix
      if (window.location.pathname === '/useraccess') {
        sessionStorage.setItem('fullscreen', false);
        setTimeout(() => {
          sessionStorage.setItem('fullscreen', true);
        }, 100);
      }
      window.onbeforeunload = unloadMessage;
      function unloadMessage() {
        sessionStorage.setItem('fullscreen', true);
        sessionStorage.setItem('once', true);
      }
      return (
        <Fragment>
          {this.renderBulkDeleteModal()}
          {this.renderDeleteModal()}
          {this.renderPage()}
          {this.state.processing && <OverlayLoader opacity={0.7} />}
          {this.props?.history?.location?.search?.includes('search') &&
            document.getElementById('realTimeSearch')?.focus()}
        </Fragment>
      );
    }
  }
  return WithContext(HeaderWrapperClass);
}

export default HeaderWrapper;
