import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { MdClose } from '../../../Common/Icons';
import { restRequest } from '../../../Helpers/RequestHelper';
import PDFViewer from '../../../Components/PDFViewer/PDFViewer';
import {
  ActionMenu,
  Button,
} from '../../../Components/CheckedMenu/CheckedMenu';
import CustomModal from '../../../Components/CustomModal/CustomModal';
import Field from '../../../Components/Form/Field/Field';
import Pagination from '../../../Components/Pagination/Pagination';
// import PaginationX from '../../../Components/PaginationRoute/Pagination'
import ResetFilterIcon from '../../../Assets/General/ResetFilter';
import { checkError } from '../../../Helpers/AuthHelper';
import { pdfDownlaod } from '../../../Helpers/PdfDownload';
import useMobileDetection from '../../../Hooks/MobileDetect';
import { Loader } from '../../../Components';

class ReportHeader extends Component {
  state = {
    showPDF: false,
    pdf: null,
    showFilterModal: false || this.props.showFilterModal,
  };

  openPdf() {
    const [hasMobile] = useMobileDetection();
    const { pdfMobileLink = '', from = '' } = this.props;
    if (hasMobile) {
      window.open(`/print/${from}?${pdfMobileLink}`, '_blank');
    } else {
      this.setState({ showPDF: true });
      restRequest('get', this.props.pdfLink)
        .then((res) => {
          this.setState({ pdf: res });
        })
        .catch((error) => {
          checkError(error);
        });
    }
  }

  closePDFModal = () => {
    this.setState({
      showPDF: false,
      pdf: null,
    });
  };

  renderPDF() {
    return (
      <PDFViewer
        showPDF={this.state.showPDF}
        hidePDFModal={this.closePDFModal}
        pdf={this.state.pdf}
      />
    );
  }
  renderWarehouseFilter() {
    return (
      <Field
        size="large"
        type="dropdown"
        name="Warehouse"
        autoFocus={true}
        className="inline-field"
        value={this.props.warehouse.selected}
        options={this.props.warehouse.options}
        dropdownValue="id"
        dropdownLabel="name"
        handleChange={this.props.warehouse.onChange}
        searchable={false}
      />
    );
  }
  renderDynamicContactFilter() {
    return (
      <>
        <div className="field-container   flex-25 form-field">
          <Field
            id="dynamicContacts"
            name="contacts"
            placeholder="Select Customer"
            autoFocus={true}
            size="large"
            clearable={false}
            // className="inline-field"
            type="DynamicPaginationDropdown"
            newCust={'CurrentCustomer'}
            value={this.props.dynamicContacts.selected}
            // editCust={values.invoiceObj}
            // disabled={
            //   owner_can_edit_in_lock_status ||
            //   inModal ||
            //   (type === 'edit' && invoice.soId)
            // }
            addButton={false}
            required
            handleChange={this.props.dynamicContacts.onChange}
            // error={touched.customerId && errors.customerId}
            alphabetSort={true}
            // onBlur={() => {
            //   setFieldTouched('customerId', true);
            // }}
            api={`report/contact?contact_type=${this.props.type}`}
          />
        </div>
      </>
    );
  }
  renderContactFilter() {
    return (
      <Field
        size="large"
        type="dropdown"
        name="Contacts"
        autoFocus={true}
        className="inline-field"
        value={this.props.contact.selected}
        options={this.props.contact.options}
        dropdownValue="id"
        dropdownLabel="display_name"
        handleChange={this.props.contact.onChange}
        searchable={true}
      />
    );
  }

  renderSalePersonFilter() {
    return (
      <Field
        size="large"
        type="dropdown"
        name="Sale Person"
        autoFocus={true}
        className="inline-field"
        value={this.props.salesperson.selected}
        options={this.props.salesperson.options}
        dropdownValue="id"
        dropdownLabel="name"
        handleChange={this.props.salesperson.onChange}
        searchable={true}
      />
    );
  }

  renderStatusFilter() {
    return (
      <Field
        size="large"
        type="dropdown"
        name="Status"
        autoFocus={true}
        value={this.props.status.selected}
        options={this.props.status.options}
        className="inline-field"
        dropdownValue="id"
        dropdownLabel="label"
        handleChange={(option) =>
          this.props.status.onChange(option.id, 'status')
        }
        clearable={false}
      />
    );
  }

  renderStockTrackingFilter() {
    return (
      <Field
        size="large"
        type="dropdown"
        autoFocus={true}
        name="Mode of Stock Tracking"
        value={this.props.stockTracking.selected}
        options={this.props.stockTracking.options}
        className="inline-field"
        dropdownValue="id"
        dropdownLabel="label"
        handleChange={(option) =>
          this.props.stockTracking.onChange(option.id, 'stockTracking')
        }
        clearable={false}
      />
    );
  }

  renderItemsFilter() {
    let items = this.props.items;
    let loading = this.props?.loading;
    let date = this.props?.dateRange?.value?.selection;
    let selected = items?.options?.filter((obj) =>
      items?.selected?.includes(obj?.id)
    );
    return (
      <>
        <Field
          size="large"
          type="multiselect"
          name="Item Name"
          autoFocus={true}
          value={items.selected}
          className={`inline-field searchable-dropdown-height ${
            this?.props?.from === 'sale_by_item' && 'reports--searchable'
          }`}
          options={items.options}
          disabled={loading}
          valueKey="id"
          labelKey="name"
          additionalSearchFilter="sku"
          handleChange={items.onChange}
          noResultsText="No items left"
          removeSelected
        />
        {this?.props?.from === 'sale_by_item' && (
          <>
            {loading && <Loader />}
            <div className="report--listing-outer">
              <ul className="report--listing">
                {selected.length > 0 && (
                  <li style={{ color: 'red' }}>
                    Currently few Items are selected{'  '}
                    <button
                      style={{ color: 'blue', textDecoration: 'underline' }}
                      onClick={() => {
                        this.props.items.selected = [];
                        items.onChange([]);
                      }}
                    >
                      Clear all
                    </button>
                  </li>
                )}
                <li>
                  Currently displaying data from all invoices,{' '}
                  <span style={{ color: 'red' }}>
                    irrespective of invoice status.
                  </span>
                </li>
                <li>
                  Data is shown for a specified date range from{' '}
                  {`${date?.startDate?.format(
                    'MMM D, YY'
                  )} to ${date?.endDate?.format('MMM D, YY')}`}
                  .
                </li>
                <li>If no results are found, adjust the date range.</li>
                {selected.length > 0 && (
                  <li>
                    Currently Selected Items:{' '}
                    <div style={{ marginLeft: '55px' }}>
                      <ul>
                        {selected.map((x, index) => (
                          <li key={x.sku}>
                            {x.name} -- {x.sku}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </>
        )}
      </>
    );
  }

  renderSingleItemFilter() {
    let item = this.props.item;
    return (
      <Field
        size="large"
        type="dropdown"
        name="Item Name/SKU"
        autoFocus={true}
        value={item.selected}
        options={item.options}
        className="inline-field"
        dropdownValue="id"
        dropdownLabel="name"
        additionalSearchFilter="sku"
        handleChange={item.onChange}
      />
    );
  }

  renderFilters() {
    let filters = [];
    for (let filter of this.props.filters) {
      switch (filter) {
        case 'warehouse':
          filters.push(this.renderWarehouseFilter());
          break;
        case 'status':
          filters.push(this.renderStatusFilter());
          break;
        case 'stockTracking':
          filters.push(this.renderStockTrackingFilter());
          break;
        case 'items':
          filters.push(this.renderItemsFilter());
          break;
        case 'item':
          filters.push(this.renderSingleItemFilter());
          break;
        case 'contact':
          filters.push(this.renderContactFilter());
          break;
        case 'salesperson':
          filters.push(this.renderSalePersonFilter());
          break;
        case 'dynamicContacts':
          filters.push(this.renderDynamicContactFilter());
          break;
        default:
          return;
      }
    }
    return filters;
  }

  renderFilterModal() {
    return (
      <CustomModal
        showModal={this.state.showFilterModal}
        title="Customize Report"
        onClose={() => this.setState({ showFilterModal: false })}
        renderActions={false}
        // minWidth={111}
        minWidth={
          this.renderFilters().length === 1
            ? 61
            : this.renderFilters().length <= 3
            ? 91
            : 111
        }
        size="large"
      >
        <div className=" report--filter-modal padding-bottom-J d-flex  reports--filter--popup">
          {this.renderFilters().map((filter, index) => (
            <Fragment key={index}>{filter}</Fragment>
          ))}
        </div>
      </CustomModal>
    );
  }
  renderResetFilter() {
    if (this.props.filters.length && this.props.showResetButton) {
      return (
        <button
          title="Reset Filter"
          className="checked-menu--button--icon-only "
          onClick={() => {
            if (this.props.filters.includes('items')) {
              this.props.items.selected = [];
              this.props.items.onChange(this.props.items.selected);
            }
            if (this.props.filters.includes('item')) {
              this.props.item.selected = [];
              this.props.item.onChange(this.props.item.selected);
            }
            if (this.props.filters.includes('warehouse')) {
              this.props.warehouse.selected = null;
              this.props.warehouse.onChange(this.props.warehouse.selected);
            }
            if (this.props.filters.includes('status')) {
              this.props.status.selected = 'all';
              this.props.status.onChange(this.props.status.selected, 'status');
            }
            if (this.props.filters.includes('contact')) {
              this.props.contact.selected = 'all';
              this.props.contact.onChange(this.props.contact.selected);
            }
            if (this.props.filters.includes('salesperson')) {
              this.props.salesperson.selected = 'all';
              this.props.salesperson.onChange(this.props.salesperson.selected);
            }
            if (this.props.filters.includes('stockTracking')) {
              this.props.stockTracking.selected = 'shipments_receives';
              this.props.stockTracking.onChange(
                'shipments_receives',
                'stockTracking'
              );
            }
          }}
        >
          <ResetFilterIcon />
        </button>
      );
    }
    return <div />;
  }

  render() {
    return (
      <Fragment>
        {this.renderPDF()}
        {this.renderFilterModal()}
        <div className="center">
          <ActionMenu>
            {/* {!this.props.hasMobile ? ( */}
            <Button
              handleClick={() =>
                pdfDownlaod(
                  `${this.props.pdfLink}&download=true`,
                  this.props.pdfName,
                  this.props.toast
                )
              }
              type="pdf"
            />
            {/* ) : null} */}
            {/* {!this.props.hasMobile ? ( */}
            <Button handleClick={() => this.openPdf()} type="print" />
            {/* // ) : null} */}
            {this.renderResetFilter()}
            {/* <Button
              handleClick={() => this.setState({showFilterModal:true})}
              type="clone"
            /> */}
            {this.props.filters && this.props.filters.length > 0 && (
              <button
                onClick={() => this.setState({ showFilterModal: true })}
                className="button--primary float-left mr-3"
              >
                Customize Report
              </button>
            )}
          </ActionMenu>
          {/* <PaginationX
            generateRoute={this.props.generateRoute}
            history={this.props.history}
            viewPerPage={this.props.viewPerPage}
            totalPage={this.props.totalPage}
            currentPage={this.props.currentPage}
            /> */}

          <Pagination
            history={this.props.history}
            viewPerPage={this.props.viewPerPage}
            totalPage={this.props.totalPage}
            currentPage={this.props.currentPage}
            url={this.props.url}
            onPageChange={this.props.onPageChange}
            onViewPerPageChange={this.props.onViewPerPageChange}
          />
          <div className="close_icon reports_close">
            <Link to="/reports">
              <MdClose />
            </Link>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ReportHeader;
