import React, { Component } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import timezone from 'moment-timezone';
import map from 'lodash/map';

import { restRequest, publicSeebizRequest } from '../../Helpers/RequestHelper';
import { checkError, logout, setOrganization } from '../../Helpers/AuthHelper';
import {
  required,
  ValidateName,
  ValidateRequired,
  ValidateEmail,
  // ValidatePhoneNumber,
  ValidateZipCode,
  ValidateMax100,
  ValidateMax200,
  ValidateMax20,
  // ValidateMax30,
  ValidateMax60,
  ValidateWebsite,
} from '../../Helpers/Validator';
import getDateFormatOptions, {
  getSelectedDateFormat,
} from '../../Helpers/DateFormatOptions';
import COUNTRY_STATES from '../../Constants/CountryState';
import { getValueLS, removeObjFromLS } from '../../Helpers/LocalStorage';
import Loader from '../../Components/Loader/InitialLoader';
import Field from '../../Components/Form/Field/Field';
import Wizard from '../../Components/Form/Wizard';
import withContext from '../../Context/UserContextHOC';
import FileUploader from '../../Components/FileUploader/FileUploader';
import AuthContainer from '../AuthContainer';
import Dots from '../Dots';
import {
  handleStreet,
  handleCity,
  handleState,
  handlePhone,
} from '../../Helpers/addressFieldsHandler';
import EmailInfo from '../../Assets/General/EmailInfo';
import { ssosignoutPath } from '../../Helpers/UrlHelper';
import { CustomModal, ImageCropper, ImagePreview } from '../../Components';
class RegisterOrganization extends Component {
  state = {
    allCurrencies: [],
    organizationTypes: [],
    fiscalYearFromDB: [],
    formFirstStep: true,
    confirmation_modal: false,
    organization: {
      organizationCurrencies: '',
      organizationName: '',
      organizationType: '',
      organizationTimezone: 'America/Los_Angeles',
      organizationInventoryStartDate: moment(),
      organizationStreet: '',
      organizationProvince: '',
      organizationPostalCode: '',
      organizationCity: '',
      organizationCountry: 230,
      phone: '',
      organizationWebsite: '',
      organizationBaseCurrency: 1,
      primaryContactName: '',
      primaryContactEmail: '',
      organizationLogo: null,
      tempOrganizationLogo: null,
      //dateFormat: 'medium1',
      dateFormat: 'long6',
      dateSeparator: '/',
      fiscalYear: '',
      license_no: '',
      showImageCropModal: false,
      base84Image: [],
    },
    countryList: [],
    countryStates: [],
    page: 0,
    loading: true,
    ssosignoutLink: ssosignoutPath(),
  };

  async componentDidMount() {
    await this.fetchOrganizationRegiseterData();
    // await this.fetchBusinessInfos();
  }

  async fetchOrganizationRegiseterData() {
    this.setState({ loading: true });
    await restRequest('get', 'register/organization').then((res) => {
      this.setState((state) => ({
        allCurrencies: res.currency_list,
        organizationTypes: res.organization_type_list,
        fiscalYearFromDB: res.fiscal_year_list,
        countryList: res.country_list,
        organization: {
          ...state.organization,
        },
        confirmation_modal: res.other_organizations_popup,
        loading: false,
      }));
    });
    await this.populateCountryStates(
      this.state.organization.organizationCountry
    );
  }

  async fetchBusinessInfos() {
    const self = this;
    self.setState({ loading: true });
    var CurrentUserEmail = self.props.context.currentUser.email;
    await publicSeebizRequest(
      'get',
      'profile/businessInfos/' + CurrentUserEmail
    )
      .then((res) => {
        if (res.status) {
          var businessType =
            res.userBusinessInfos.businessInfo.businessCategory
              .bussinessCategoryName;
          self.state.organizationTypes.filter((type) => {
            if (businessType === type.label)
              return (self.state.organization.organizationType = type.id);
            return false;
          });
          self.state.organization.organizationName =
            res.userBusinessInfos.businessInfo.businessName;
          self.setState((state) => ({
            loading: false,
          }));
        } else {
          self.setState((state) => ({
            loading: false,
          }));
        }
      })
      .catch((error) => {
        self.setState((state) => ({
          loading: false,
        }));
      });
  }

  populateCountryStates = (countryId) => {
    if (countryId) {
      const countryFromList = this.state.countryList.find(
        (country) => country.id === countryId
      );
      if (countryFromList) {
        const country = COUNTRY_STATES.find(
          (item) => item.country === countryFromList.country_name
        );
        if (country) {
          const states = country.states.map((state) => ({
            value: state,
            label: state,
          }));
          this.setState({ countryStates: states });
          return;
        }
      }
    }
    this.setState({ countryStates: [] });
  };

  handleSubmit(values, setSubmitting) {
    values.package_id = '';
    var packageId = getValueLS('package_id');
    if (packageId) {
      var slug = packageId.split('"');
      values.package_id = slug[1];
      removeObjFromLS('package_id');
    }
    let data = new FormData();
    data.append('name', values.organizationName);
    data.append('organization_type_id', values.organizationType);
    data.append('fiscal_year_id', values.fiscalYear);
    data.append('company_street', values.organizationStreet);
    data.append('company_province', values.organizationProvince);
    data.append('company_postal_code', values.organizationPostalCode);
    data.append('company_city', values.organizationCity);
    values.organizationCountry &&
      data.append('country_id', values.organizationCountry);
    data.append('phone', values.phone);
    data.append('company_website', values.organizationWebsite);
    data.append(
      'inventory_start_date',
      values.organizationInventoryStartDate
        ? values.organizationInventoryStartDate.format('MM/DD/YYYY')
        : null
    );
    data.append('base_currency_id', values.organizationBaseCurrency);
    data.append('time_zone', values.organizationTimezone);
    data.append('primary_contact_name', values.primaryContactName);
    data.append('primary_contact_email', values.primaryContactEmail);
    data.append('date_format', values.dateFormat);
    data.append('date_separator', values.dateSeparator);
    data.append('base84Image', values.base84Image ?? '');

    // data.append('logo', values.organizationLogo);
    data.append('package_id', values.package_id);
    data.append('license_no', values.license_no);
    if (!values.organizationLogo) {
      data.delete('logo');
    }
    restRequest('post', 'register/organization', data)
      .then((res) => {
        const visitPackagePlanPage = res.visit_package_plan_page;
        const organizationId = res.data.id;
        setOrganization(organizationId);
        restRequest('get', `organizations/${organizationId}`).then((res) => {
          localStorage.setItem('currentOrganization', JSON.stringify(res));
          this.props.onSubmit(this.props.fetchPermissions);
          if (visitPackagePlanPage) {
            this.redirectToPlans();
          }
        });
      })
      .catch((error) => {
        //this.props.handleToast(error, 'error');
        checkError(error, this.props.handleToast);
        setSubmitting(false);
      });
  }

  handleCurrencyOptions(currencies) {
    const renderOptions = [];
    map(currencies, (currency) => {
      let option = {
        value: currency.id,
        label: `${currency.currency_code} - ${currency.symbol} - ${currency.name}`,
      };
      renderOptions.push(option);
    });
    return renderOptions;
  }
  ImageCropModal = (values, setFieldValue, handleDelete) => {
    var base64Images = [];

    // console.log(props, 'props IN IMAGE ');
    return (
      <CustomModal
        className="upload_img_popup"
        showModal={values.showImageCropModal}
        title="Adjust Image"
        renderActions={false}
        onClose={
          () => {
            setFieldValue('showImageCropModal', false);
            // handleDelete();
            // setFieldValue('organizationLogo', '');
          }
          // this.setState({
          //   showImageCropModal: false,
          //   imageFile: [],
          //   base64Images: [],
          //   imageUploading: false,
          // })
        }
      >
        <ImageCropper
          inModal
          updatedFiles={values.tempOrganizationLogo}
          type="add"
          onSubmitModal={(updatedFiles, croppedImage) => {
            // handleImageUpload(updatedFiles, croppedImage);
            updatedFiles[updatedFiles.length - 1].preview = croppedImage;
            updatedFiles.forEach((el) => {
              base64Images.push(el.preview);
              setFieldValue('base84Image', base64Images);
            });
            setFieldValue('organizationLogo', updatedFiles);
            setFieldValue('showImageCropModal', false);

            // this.setState({ imageUploading: true, alreadyUploadImage: true });
          }}
          closeModal={
            () => {
              setFieldValue('showImageCropModal', false);
              // handleDelete();
            }

            // this.setState({
            //   showImageCropModal: false,
            //   imageFile: [],
            //   base64Images: [],
            //   imageUploading: false,
            //   alreadyUploadImage: false,
            // })
          }
          // handleToast={this.props.handleToast}
          // alreadyUploadInprogress={this.state.alreadyUploadImage}
        />
      </CustomModal>
    );
  };
  renderOrganizationDateFormat = (values, setFieldValue) => {
    const { dateSeparator, dateFormat } = values;

    const options = getDateFormatOptions(dateSeparator);

    return (
      <Field
        disabled={true}
        className="inline-field-mr-2"
        size="large"
        id="dateFormat"
        type="groupdropdown"
        name="Date Format"
        value={getSelectedDateFormat(dateFormat, dateSeparator)}
        options={options}
        handleChange={(option) =>
          setFieldValue('dateFormat', option ? option.value : null)
        }
        clearable={false}
      />
    );
  };

  handleFiles = (files, setFieldValue, setFieldTouched, values) => {
    const file = files[0];

    const allowedExtensions = [
      'image/png',
      'image/jpg',
      'image/jpeg',
      'image/webp',
    ];
    if (file && !allowedExtensions.includes(file.type)) {
      this.props.handleToast(' invalid file/file size.', 'error');
      return null;
    }

    if (!file) {
      this.props.handleToast('Invalid file/file size.', 'error');
      return;
    }
    let updatedFiles = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    // Combine new files with existing ones
    setFieldValue('tempOrganizationLogo', updatedFiles);
    // setFieldValue('organizationLogo', updatedFiles);

    // setFieldValue('organizationLogo', file, ...updatedFiles);
    setFieldValue('showImageCropModal', true);
    setFieldTouched('organizationLogo');
  };

  setFocus = (errors) => {
    if (errors[0] === 'organizationName') {
      window.scrollTo(0, 0);
    }
  };

  renderFormFirstStep({
    values = {},
    touched = {},
    errors = {},
    setFieldTouched,
    setFieldValue,
    handleBlur,
    handleChange,
  }) {
    const { organizationTypes, allCurrencies, fiscalYearFromDB } = this.state;
    const dateSeparators = [
      {
        label: '-',
        value: '-',
      },
      {
        label: '.',
        value: '.',
      },
      {
        label: '/',
        value: '/',
      },
    ];
    // const FILE_SIZE = 5000 * 1024
    Object.keys(errors).length > 0 && this.setFocus(Object.keys(errors));
    const handleDelete = () => {
      // handleChangeLogo('');
      setFieldValue('organizationLogo', null);
      setFieldTouched('organizationLogo');
      setFieldValue('base84Image', '');

      // props.setPrompt();
    };
    return (
      <div className="auth--form">
        <Field
          size="large"
          name="Business Name"
          value={values.organizationName}
          id="organizationName"
          autoFocus={true}
          required
          onBlur={handleBlur}
          handleChange={handleChange}
          error={touched.organizationName && errors.organizationName}
        />
        <div className="form-field">
          <label className="form-label">Add Logo</label>
          <div className="form-image">
            <FileUploader
              accept="image/*"
              onDrop={(files) =>
                this.handleFiles(
                  files,
                  setFieldValue,
                  setFieldTouched,
                  values.organizationLogo
                )
              }
              maxSize={1 * 1000 * 1000}
              circular
              camIcon={true}
            />
            {values?.organizationLogo?.length && (
              <div className="img--preview">
                <ImagePreview
                  images={
                    values.organizationLogo?.length > 0
                      ? values.organizationLogo
                      : []
                    // : []
                  }
                  onDeleteImage={handleDelete}
                />
              </div>
            )}
            {this.ImageCropModal(values, setFieldValue, handleDelete)}

            <input name="organizationLogo" className="input-zero" />
          </div>
        </div>
        <Field
          size="large"
          id="organizationType"
          type="dropdown"
          className="reg_business_type_field"
          name="Type"
          placeholder="Business Type"
          value={values.organizationType}
          options={organizationTypes}
          dropdownValue="id"
          dropdownLabel="label"
          error={touched.organizationType && errors.organizationType}
          onBlur={handleBlur}
          handleChange={(option) =>
            setFieldValue('organizationType', option ? option.value : null)
          }
        />
        <Field
          size="large"
          id="organizationTimezone"
          type="dropdown"
          className="reg_timezone_field"
          name="Timezone"
          value={values.organizationTimezone}
          options={timezone.tz.names()}
          dropdownValue="value"
          dropdownLabel="label"
          required
          clearable={false}
          handleChange={(option) =>
            setFieldValue('organizationTimezone', option ? option.value : null)
          }
        />
        <Field
          size="large"
          id="fiscalYear"
          className="reg_fiscal_year_field"
          type="dropdown"
          name="Fiscal Year"
          value={values.fiscalYear}
          options={fiscalYearFromDB}
          dropdownValue="id"
          dropdownLabel="fiscal_year"
          handleChange={(option) =>
            setFieldValue('fiscalYear', option ? option.value : null)
          }
          clearable={false}
          placeholder="First month - Last month"
          onBlur={handleBlur}
          error={touched.fiscalYear && errors.fiscalYear}
          required
        />
        <Field
          disabled={true}
          size="large"
          type="date"
          name="Inventory Start Date"
          id="organizationInventoryStartDate"
          value={values.organizationInventoryStartDate}
          onBlur={() => setFieldTouched('organizationInventoryStartDate')}
          handleChange={(date) =>
            setFieldValue('organizationInventoryStartDate', date)
          }
          error={
            touched.organizationInventoryStartDate &&
            errors.organizationInventoryStartDate
          }
          required
        />
        <div className="form-field inline-field base_field">
          <Field
            size="large"
            id="organizationBaseCurrency"
            type="dropdown"
            className="reg_vase_currency_field"
            name="Base Currency"
            value={values.organizationBaseCurrency}
            options={this.handleCurrencyOptions(allCurrencies)}
            dropdownValue="value"
            dropdownLabel="label"
            onBlur={handleBlur}
            handleChange={(option) =>
              setFieldValue(
                'organizationBaseCurrency',
                option ? option.value : null
              )
            }
            clearable={false}
            required
            error={
              touched.organizationBaseCurrency &&
              errors.organizationBaseCurrency
            }
          />
          <div
            title="This filed can't be edited once a SO, PO, Invoice, or Bill is created."
            className="currency_field"
          >
            <EmailInfo className="currnecy_info" />
          </div>
          <Field
            size="large"
            name="License No"
            placeholder="License No"
            value={values.license_no}
            id="license_no"
            className="inline-field"
            onBlur={handleBlur}
            handleChange={handleChange}
            error={touched.license_no && errors.license_no}
          />
        </div>

        {this.renderOrganizationDateFormat(values, setFieldValue)}
        {!values.dateFormat.includes('long') && (
          <Field
            disabled={true}
            className="inline-field-mr-2"
            id="dateSeparator"
            type="dropdown"
            name="Date Separator"
            value={values.dateSeparator}
            options={dateSeparators}
            dropdownValue="value"
            dropdownLabel="label"
            handleChange={(option) =>
              setFieldValue('dateSeparator', option ? option.value : null)
            }
            clearable={false}
          />
        )}
      </div>
    );
  }

  handlePageChange = (page) => {
    this.setState({
      page: page,
    });
  };

  getPageTitles(page) {
    if (page === 1)
      return {
        title: 'Basic contact information to get started',
        subtitle: 'You can fill optional information later',
      };
    return {
      title: 'Tell us about your business',
      subtitle: 'It will help us to serve you better',
    };
  }

  logout = () => {
    const sso_connected =
      process.env.REACT_APP_SSO_ENABLED === 'false' ? false : true;
    this.setState({
      loading: true,
    });
    // restRequest('get', 'ssoSettings')
    //   .then((res) => {
    if (sso_connected) {
      window.location.href = this.state.ssosignoutLink;
      //this.setState({ loading: false });
    } else {
      logout();
      this.props.history.push('/');
    }
    // })
    // .catch((e) => {
    //   this.setState({ loading: false });
    // });
    /*logout();
    this.props.history.push('/');*/
  };

  renderLogout() {
    return (
      <p className="auth--logout">
        Welcome{' '}
        <span className="semi-bold">{this.props.context.currentUser.name}</span>{' '}
        |
        <button className="button--no-background link" onClick={this.logout}>
          Logout
        </button>
      </p>
    );
  }

  // renderModal() {

  //   />
  // }

  renderForm() {
    const { organization, page } = this.state;
    const { title, subtitle } = this.getPageTitles(page);

    return (
      <>
        <CustomModal
          showModal={this.state.confirmation_modal}
          title="Confirmation Modal"
          confirmLabel="Navigate to Books"
          size="medium"
          closeLabel="Register on Inventory"
          onSubmit={() => (window.location = process.env.REACT_APP_BOOKS_URL)}
          onClose={() => this.setState({ confirmation_modal: false })}
          renderActions={true}
        >
          You have registered business(es) on SeeBiz Books Management System.
        </CustomModal>
        <AuthContainer
          className="md-width"
          title={title}
          subtitle={subtitle}
          logout={this.renderLogout()}
        >
          <Dots page={page} />
          <Wizard
            initialValues={{
              ...organization,
            }}
            onSubmit={(values, { setSubmitting }) => {
              this.handleSubmit(values, setSubmitting);
            }}
            handlePageChange={this.handlePageChange}
          >
            <Wizard.Page
              validationSchema={() =>
                Yup.object().shape({
                  organizationName: required(ValidateName),
                  // organizationType: ValidateRequired.nullable(),
                  fiscalYear: ValidateRequired.nullable(),
                  license_no: ValidateMax60.nullable(),
                  organizationInventoryStartDate: ValidateRequired.nullable(),
                  organizationBaseCurrency: ValidateRequired.nullable(),
                })
              }
            >
              {(props) => this.renderFormFirstStep(props)}
            </Wizard.Page>
            <Wizard.Page
              validationSchema={() =>
                Yup.object().shape({
                  primaryContactName: ValidateMax100,
                  primaryContactEmail: ValidateEmail,
                  organizationWebsite: ValidateWebsite,
                  organizationStreet: ValidateMax200,
                  organizationCity: ValidateMax100,
                  organizationPhone: ValidateMax20,
                  license_no: ValidateMax60.nullable(),
                  organizationProvince: ValidateMax100,
                  organizationPostalCode: ValidateZipCode,
                  phone: required(ValidateMax20),
                })
              }
            >
              {(props) => this.renderFormSecondStep(props)}
            </Wizard.Page>
          </Wizard>
        </AuthContainer>
      </>
    );
  }

  redirectToPlans() {
    this.props.history.push('/packagesplan');
  }
  renderFormSecondStep({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
  }) {
    if (values.organizationPostalCode.toString().length > 15) {
      values.organizationPostalCode = values.organizationPostalCode.toString();
      values.organizationPostalCode = values.organizationPostalCode.slice(
        0,
        15
      );
    }
    return (
      <div className="auth--form">
        <Field
          size="large"
          name="Contact Name"
          value={values.primaryContactName}
          id="primaryContactName"
          handleChange={handleChange}
          onBlur={handleBlur}
          error={touched.primaryContactName && errors.primaryContactName}
        />
        <Field
          size="large"
          name="Contact Email"
          value={values.primaryContactEmail}
          id="primaryContactEmail"
          handleChange={handleChange}
          onBlur={handleBlur}
          error={touched.primaryContactEmail && errors.primaryContactEmail}
        />

        <Field
          size="large"
          name="Street Address"
          value={values.organizationStreet}
          id="organizationStreet"
          handleChange={(value) =>
            setFieldValue('organizationStreet', handleStreet(value, 200))
          }
          onBlur={handleBlur}
          error={touched.organizationStreet && errors.organizationStreet}
        />

        <Field
          className="inline-field"
          name="City"
          value={values.organizationCity}
          id="organizationCity"
          handleChange={(value) =>
            setFieldValue('organizationCity', handleCity(value, 100))
          }
          onBlur={handleBlur}
          error={touched.organizationCity && errors.organizationCity}
        />

        <Field
          className="inline-field"
          id="organizationProvince"
          name="State / Province"
          placeholder="State / Province"
          value={values.organizationProvince}
          handleChange={(value) =>
            setFieldValue('organizationProvince', handleState(value, 100))
          }
          onBlur={handleBlur}
          error={touched.organizationProvince && errors.organizationProvince}
        />

        <Field
          size="large"
          name="Zip Code"
          value={values.organizationPostalCode}
          id="organizationPostalCode"
          onBlur={handleBlur}
          handleChange={handleChange}
          onValueChange={(value) =>
            setFieldValue('organizationPostalCode', value.floatValue)
          }
          error={
            touched.organizationPostalCode && errors.organizationPostalCode
          }
        />

        <Field
          size="large"
          type="dropdown"
          name="Country"
          value={values.organizationCountry}
          options={this.state.countryList}
          dropdownLabel="country_name"
          dropdownValue="id"
          required
          handleChange={(option) => {
            setFieldValue('organizationCountry', option ? option.value : null);
          }}
          clearable={false}
        />

        <Field
          size="large"
          name="Phone"
          value={values.phone}
          id="phone"
          handleChange={(value) =>
            setFieldValue('phone', handlePhone(value, 20))
          }
          required
          onBlur={handleBlur}
          error={touched.phone && errors.phone}
        />

        <Field
          size="large"
          name="Website"
          value={values.organizationWebsite}
          id="organizationWebsite"
          handleChange={handleChange}
          onBlur={handleBlur}
          error={touched.organizationWebsite && errors.organizationWebsite}
        />
      </div>
    );
  }

  render() {
    console.log('this.props', this.props);
    if (this.state.loading)
      return (
        <div>
          <Loader />
        </div>
      );
    return this.renderForm();
  }
}

export default withContext(RegisterOrganization);
